import {
  Directive,
  Host,
  Optional,
  Renderer2,
  Self,
  ViewContainerRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatButton } from '@angular/material/button';
import * as _ from 'lodash';

interface PageObject {
  length: number;
  pageIndex: number;
  pageSize: number;
  previousPageIndex: number;
}

@Directive({
  selector: '[number-style-paginator]',
})
export class NumberStylePaginatorDirective implements OnChanges {
  private pageGapTxt = '...';
  private rangeStart!: number;
  private rangeEnd!: number;
  private buttons: any[] = [];
  private curPageObj: PageObject = {
    length: 0,
    pageIndex: 0,
    pageSize: 0,
    previousPageIndex: 0,
  };

  @Input() showTotalPages: number = 2;
  @Input() pageIndex: number = 0;

  get inc(): number {
    return this.showTotalPages % 2 == 0
      ? this.showTotalPages / 2
      : (this.showTotalPages - 1) / 2;
  }

  get numOfPages(): number {
    return this.matPag.getNumberOfPages();
  }

  get lastPageIndex(): number {
    return this.matPag.getNumberOfPages() - 1;
  }

  constructor(
    @Host() @Self() @Optional() private readonly matPag: MatPaginator,
    private vr: ViewContainerRef,
    private ren: Renderer2
  ) {
    //to rerender buttons on items per page change and first, last, next and prior buttons
    this.matPag.page.subscribe((e: PageObject) => {
      if (
        this.curPageObj.pageSize != e.pageSize &&
        this.curPageObj.pageIndex != 0
      ) {
        e.pageIndex = 0;
        this.rangeStart = 0;
        this.rangeEnd = this.showTotalPages - 1;
      }
      this.curPageObj = e;

      this.initPageRange();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('pageIndex' in changes) {
      if (changes['pageIndex'].currentValue !== this.curPageObj.pageIndex) {
        this.curPageObj.pageIndex = changes['pageIndex'].currentValue;
        this.initPageRange();
      }
    }
    if ('showTotalPages' in changes) {
      this.initPageRange();
    }
  }

  private buildPageNumbers() {
    const actionContainer = this.vr.element.nativeElement.querySelector(
      'div.mat-paginator-range-actions'
    );
    const nextPageNode = this.vr.element.nativeElement.querySelector(
      'button.mat-paginator-navigation-next'
    );
    // remove buttons before creating new ones
    if (this.buttons.length > 0) {
      this.buttons.forEach((button) => {
        this.ren.removeChild(actionContainer, button);
      });
      this.buttons.length = 0;
    }

    _.forEach(_.range(this.rangeStart, this.rangeEnd + 1), (i) => {
      this.ren.insertBefore(
        actionContainer,
        this.createButton(i, this.matPag.pageIndex),
        nextPageNode
      );
    });
  }

  private createButton(i: any, pageIndex: number): any {
    const linkBtn: MatButton = this.ren.createElement('mat-button');

    if (i === pageIndex) {
      this.ren.addClass(linkBtn, 'mat-mini-fab');
      this.ren.addClass(linkBtn, 'elavation-z0');
    } else this.ren.addClass(linkBtn, 'mat-button');

    const pagingTxt = isNaN(i) ? this.pageGapTxt : +(i + 1);
    const text = this.ren.createText(pagingTxt + '');

    this.ren.addClass(linkBtn, 'mat-custom-page');
    switch (i) {
      case pageIndex:
        this.ren.setAttribute(linkBtn, 'disabled', 'disabled');
        break;
      case this.pageGapTxt:
        let newIndex = this.curPageObj.pageIndex + this.showTotalPages;

        if (newIndex >= this.numOfPages) newIndex = this.lastPageIndex;

        if (pageIndex != this.lastPageIndex) {
          this.ren.listen(linkBtn, 'click', () => {
            this.switchPage(newIndex);
          });
        }

        if (pageIndex == this.lastPageIndex) {
          this.ren.setAttribute(linkBtn, 'disabled', 'disabled');
        }
        break;
      default:
        this.ren.listen(linkBtn, 'click', () => {
          this.switchPage(i);
        });
        break;
    }
    this.ren.appendChild(linkBtn, text);
    //Add button to private array for state
    this.buttons.push(linkBtn);
    return linkBtn;
  }
  //calculates the button range based on class input parameters and based on current page index value. Used to render new buttons after event.
  private initPageRange(): void {
    const middleIndex = (this.rangeStart + this.rangeEnd) / 2;

    this.rangeStart = this.calcRangeStart(middleIndex);
    this.rangeEnd = this.calcRangeEnd(middleIndex);

    this.buildPageNumbers();
  }
  //Helper function To calculate start of button range
  private calcRangeStart(middleIndex: number): number {
    switch (true) {
      case this.curPageObj.pageIndex == 0 && this.rangeStart != 0:
        return 0;
      case this.curPageObj.pageIndex > this.rangeEnd:
        return this.curPageObj.pageIndex + this.inc > this.lastPageIndex
          ? this.lastPageIndex - this.inc * 2
          : this.curPageObj.pageIndex - this.inc;
      case this.curPageObj.pageIndex > this.curPageObj.previousPageIndex &&
        this.curPageObj.pageIndex > middleIndex &&
        this.rangeEnd < this.lastPageIndex:
        return this.rangeStart + 1;
      case this.curPageObj.pageIndex < this.curPageObj.previousPageIndex &&
        this.curPageObj.pageIndex < middleIndex &&
        this.rangeStart > 0:
        return this.rangeStart - 1;
      default:
        return this.rangeStart;
    }
  }
  //Helpter function to calculate end of button range
  private calcRangeEnd(middleIndex: number): number {
    switch (true) {
      case this.curPageObj.pageIndex == 0 &&
        this.rangeEnd != this.showTotalPages:
        return this.showTotalPages - 1;
      case this.curPageObj.pageIndex > this.rangeEnd:
        return this.curPageObj.pageIndex + this.inc > this.lastPageIndex
          ? this.lastPageIndex
          : this.curPageObj.pageIndex + 1;
      case this.curPageObj.pageIndex > this.curPageObj.previousPageIndex &&
        this.curPageObj.pageIndex > middleIndex &&
        this.rangeEnd < this.lastPageIndex:
        return this.rangeEnd + 1;
      case this.curPageObj.pageIndex < this.curPageObj.previousPageIndex &&
        this.curPageObj.pageIndex < middleIndex &&
        this.rangeStart >= 0 &&
        this.rangeEnd > this.showTotalPages - 1:
        return this.rangeEnd - 1;
      default:
        return this.rangeEnd;
    }
  }
  //Helper function to switch page on non first, last, next and previous buttons only.
  private switchPage(i: number): void {
    const previousPageIndex = this.matPag.pageIndex;
    this.matPag.pageIndex = i;
    this.matPag['_emitPageEvent'](previousPageIndex);
    this.initPageRange();
  }
  //Initialize default state after view init
  public ngAfterViewInit() {
    this.rangeStart = 0;
    this.rangeEnd = this.showTotalPages - 1;
    this.initPageRange();
  }
}

import { Inject, Injectable } from '@angular/core';
import { Howl, Howler } from 'howler';
import { UserProfileService } from './user-profile.service';

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  private soundMap: { [key: string]: Howl } = {
    success: new Howl({ src: ['assets/sounds/success.mp3'] }),
    warning: new Howl({ src: ['assets/sounds/warning.mp3'] }),
    error: new Howl({ src: ['assets/sounds/error.mp3'] }),
    notification: new Howl({ src: ['assets/sounds/notification.mp3'] }),
    dialogBox: new Howl({ src: ['assets/sounds/dialog-box.mp3'] }),
    case: new Howl({ src: ['assets/sounds/case.mp3'] }),
  };
  constructor(
    private readonly userProfileService: UserProfileService,
  ) {}

  playSound(soundName: string) {
    const isMuted = this.userProfileService.getUserConfig()?.muteNotifSound;
    if(!isMuted) {
      this.soundMap[soundName]?.play();
    }
  }
}

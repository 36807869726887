import { Injectable } from '@angular/core';
import { StringHelper } from '@bryntum/scheduler';
import { LegendColors, ProductLegendStyle } from '../../../shared/helpers/legends-list';
import { NumericEventModel } from '../../product-availability/models/events/numeric-event.model';

@Injectable({ providedIn: 'root' })
export class RTLAreaBookingsRangeRenderingService {
  static ResourceRender(record: any): string {
    const resourceRecord = JSON.stringify(record.data);
    return StringHelper.xss`<timeline-resource-column record="${resourceRecord}"></timeline-resource-column>`;
  }

  static EventRangeRndrer(
    eventRangeRecord: any,
    rendererData: any,
    resourceRecord: any
  ): string {
    const eventRange = JSON.stringify(eventRangeRecord.originalData);
    if (
      eventRangeRecord.constructor === NumericEventModel
    ) {
      return StringHelper.xss`<rtl-area-booking-numeric-range height="${rendererData.height}" width="${rendererData.width}" record="${eventRange}"></rtl-area-booking-numeric-range>`;
   }
    else
    return StringHelper.xss`<rtl-area-booking-range height="${rendererData.height}" width="${rendererData.width}" record="${eventRange}"></rtl-area-booking-range>`;
  }

  getRangeColors(eventType: string): ProductLegendStyle {
    let rangeStyle = LegendColors[eventType];
    if (!rangeStyle) {
      rangeStyle = LegendColors['any'];
    }
    return rangeStyle;
  }
}

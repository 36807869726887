<div class="area-range" [style.height.px]="height" [style.width.px]="width-2"
  [style.backgroundColor]="rangeStyle.backgroundColor" [style.borderColor]="rangeStyle.borderColor" matTooltip="{{tooltipRangeTemplate}}" matTooltipPosition="below">
  <div class="range-text">
    <div *ngIf="eventRecord?.sheet?.name && eventRecord?.sheet?.link" class="info-block">
      <a [href]="eventRecord.sheet.link" target="_blank">
      <mat-icon class="info-icon" *ngIf="eventRecord?.sheet?.type === 'RETURNSHEET'">login</mat-icon>
      <mat-icon class="info-icon" *ngIf="eventRecord?.sheet?.type === 'PULLSHEET'">logout</mat-icon>
      </a>
    </div>
    <div *ngIf="eventRecord?.quote?.name" class="info-block">
      <span>
        <a [href]="eventRecord.quote.link" target="_blank">
        <mat-icon class="info-icon">article</mat-icon>
        </a>
      </span>
    </div>
    <span *ngIf="eventRecord?.sheet?.link || eventRecord?.sheet?.name" class="info-divider">
      /
    </span>
    <div *ngIf="eventRecord?.sheet?.link">
      <a [href]="eventRecord.sheet.link" target="_blank">
        <div class="link">
          {{ eventRecord.sheet.name }}
        </div>
      </a>
    </div>
    <div *ngIf="!eventRecord?.sheet?.link && eventRecord?.sheet?.name">
      {{ eventRecord.sheet.name }}
    </div>
  </div>
</div>

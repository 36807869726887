import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { HEADER_PARAMS } from '../modules/shared/helpers/enums';
import { LoaderService } from '../modules/shared/services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests = 0;

  constructor(private readonly loaderService: LoaderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    !req.headers.get(HEADER_PARAMS.SHOW_NO_LOADER) && this.requests++;
    this.requests && this.loaderService.setSpinnerState(true);

    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          // this.removeRequest();
        }
      }),
      finalize(() => {
        !req.headers.get(HEADER_PARAMS.SHOW_NO_LOADER) && this.removeRequest();
        if (!this.requests) {
          this.loaderService.setSpinnerState(false);
          this.loaderService.setProgressBarState(false);
        }
      })
    );
  }

  private removeRequest() {
    this.requests > 0 && this.requests--;
  }
}

import { U } from '@angular/cdk/keycodes';
import { MOVEMENT_WIZARD_DIMENSIONS } from './constants';
// This will be used to define all Enums that will be available in whole application.
export enum STORAGE {
  DARK_THEME = 'darkTheme',
  USER = 'user',
  USERCONFIG = 'userConfig',
  TOKEN = 'accessToken',
  AUTHORIZATION = 'oauth_token',
  EXPIRE_IN = 'expiresIn',
  NAVIGATION_BAR_EXPANDED = 'navigationBarExpanded',
  SESSION_EXPIRE_IN = 'sessionExpiresIn',
  SESSION_EXPIRE_AT = 'sessionExpiresAt',
  TOKEN_EXPIRE_AT = 'tokenExpiresAt',
  SELECTED_CAMERA = 'selectedCamera',
  PULL_SHEET_DEFAULTS = 'pullSheetDefaults',
  PULL_SHEET_DRAWER = 'pullSheetDrawer',
  RETURN_SHEET_DEFAULTS = 'returnSheetDefaults',
  RETURN_SHEET_DRAWER = 'returnSheetDrawer',
  KIT_BUILDING_DRAWER = 'kitBuildingDrawer',
  KEEP_CAMERA_OPEN = 'keepCameraOpen',
  KIT_BUILD_PREVIOUS_SIBLINGS = 'kitBuildPreviousSiblings',
  KIT_BUILDING_ACTION = 'kitBuildingAction',
  PREVIOUS_ROUTE = 'previousRoute',
}

export enum HEADER_PARAMS {
  SHOW_NO_LOADER = 'showNoLoader',
  API_TIME_OUT = 'apiTimeOut',
  IS_PING = 'isPing',
}

export enum FORM_TYPE {
  CREATE = 'create',
  UPDATE = 'update',
  VIEW = 'view',
}

export enum USER_ROLES {
  WAREHOUSE_WORKER = 'Warehouse Worker',
  FREELANCER = 'Freelancer',
  DRIVER = 'Driver',
  STAFF = 'Staff',
  WAREHOUSE_MANAGER = 'Warehouse Manager',
  LOGISTICS_MANAGER = 'Logistic Manager',
}

export enum EQUIPMENT_PURPOSE {
  INDIVIDUAL_ITEM = 'individual_item',
  GROUP = 'group',
  GROUP_ITEM = 'group_item',
  CASE = 'case',
  KIT = 'kit',
  KIT_GROUP = 'kitgroup',
  SERIALIZED_GROUPS = 'serialized_groups',
  EXPENDABLE = 'expendable',
  STANDARD_SALE_ITEM = '',
  SUPER_SERIALIZED_GROUP = 'super_serialized_group',
}

export enum USER_ACTION {
  SCAN = 'scanning',
  LOCATE_ITEMS = 'locating items',
}

export enum LOOSE_CASE {
  ID = 'LOOSE',
  NAME = 'Loose',
}

export enum LOOSE_COMPARTMENT {
  ID = '',
  NAME = '-',
}

export enum PS_SCANNING_STAGES {
  LOADED = 'Loaded',
  RESERVED = 'Reserved',
  PREPPING = 'Prepping',
  PULLED = 'Pulled',
  SHELVED = 'Shelved',
  ONSITE = 'On Site',
  Staging = 'Staging',
}

export enum PS_EXCLUSIVE_STAGES {
  PULLING_IN_PROGRESS = 'Pulling In Progress',
  LOADING_IN_PROGRESS = 'Loading In Progress',
}

export enum RS_SCANNING_STAGES {
  ON_SITE = 'On Site',
  CHECKED_IN = 'Checked In',
  SHELVED = 'Shelved',
  Unloaded = 'unloaded',
}

export enum EQUIPMENT_EXCULSIVE_STAGES {
  NEW = 'Stock',
  MISSING = 'Missing',
  STOCK = 'Stock',
  LOST_STOLEN = 'lost_stolen', //for ind item & kit
  SOLD = 'sold', //for ind item & kit
  REPAIR = 'repair', //for ind item & kit
  RETIRED = 'retired', //for ind item & kit
  CREATED = 'Created',
  LINKED = 'Linked',
  UNLINKED = 'unlinked',
  INTACT = 'Intact',
}

export enum HISTORY_LOCATION_TYPES {
  JOB_SITE = 'Job Site',
  TRUCK = 'Truck',
  WAREHOUSE = 'Warehouse',
  AREA = 'Area',
  RACK = 'Rack',
  SHELF = 'Shelf',
  BIN = 'Bin',
}

export const RS_HEADER_STAGES = {
  CHECKED_IN: RS_SCANNING_STAGES.CHECKED_IN,
  SHELVED: RS_SCANNING_STAGES.SHELVED,
};

export const RS_AREA_TYPES = {
  'Checked In': RS_SCANNING_STAGES.CHECKED_IN,
  unloaded: RS_SCANNING_STAGES.Unloaded,
};

export const PS_AREA_TYPES = {
  Staging: PS_SCANNING_STAGES.Staging,
  Prepping: PS_SCANNING_STAGES.PREPPING,
};

export enum ItemApprovalStatus {
  APPROVED = 'Approved',
  PENDING = 'Pending',
  SHELVED = 'Shelved',
  PERMITTED = 'Permitted',
}

export enum EXPENDABLE_RETURNED {
  EXPENDABLE_RETURNED_VALUE = 'expendable_returned',
  EXPENDABLE_RETURNED_LABEL = 'Add New',
}

export enum LOCATION_TYPE {
  CURRENTLOCATION = 'Current Location',
  HOMELOCATION = 'Home Location',
}

export enum STAGES {
  PREPPING = 'Prepping',
  PULLED = 'Pulled',
  LOADED = 'Loaded',
  IN_TRANSIT = 'In Transit',
  ONSITE = 'On Site',
}

export enum SOUND_NAME {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  CASE = 'case',
  DIALOG_BOX = 'dialogBox',
}

export enum PROMPT_MESSAGES {
  ALREADY_ADDED = 'Scanned item already exists!',
  RENTAL_KIT_IN_SALE_KIT = 'Rental kit is not allowed to be pulled in the sale kit.',
  SALE_KIT_IN_RENTAL_KIT = 'Sale kit is not allowed to be pulled in the rental kit.',
  CASE_CANNOT_SCANNED = 'The scanned case does not have a kit',
}

export enum ITEM_TYPE {
  SALE = 'Sale',
  SUBRENTAL = 'Subrental',
  RENTAL = 'Rental',
}
export enum SALE_ITEM_MODE {
  READ = 'Read',
  CREATE = 'Create',
  EDIT = 'Edit',
}

export enum VIEW_RENDERING_COMPONENT {
  KIT_BUILDING = 'Kit',
  PULL_SHEET = 'PullSheet',
  EQUIPMENT_VIEW = 'EquipmentView',
  RETURN_SHEET = 'ReturnSheet',
}

export enum API_CONTEXT_MODE {
  EQUIPMENT_TAB = 'equipmentTab',
  PULLSHEET = 'currentPullsheet',
  INVENTORY = 'inventory',
  ONSITE = 'onsite',
  LOADED = 'loaded',
  UNAVAILABLE_ITEMS = 'unavailableItems',
}

export enum CONTAINED_IN_OPTIONS {
  KIT = 'kit',
  LOOSE = 'loose',
}

export enum CALENDAR_RANGE_TYPES {
  AVAILABILITY = 'available',
  QUOTED = 'quoted',
  RESERVED = 'reserved',
  BOOKED = 'booked',
  PULLED = 'pulled',
  MISSING = 'missing',
  REPAIR = 'repair',
  ONSITE = 'onsite',
}

export enum FRACTION_RANGE_TYPES {
  WARNING = 'warning',
  SHORTAGE = 'shortage',
  PARTITIONED = 'partitioned',
  Available = 'available',
}

export enum CALENDAR_RANGE_STATUS_TYPES {
  LOST_STOLEN = 'lost_stolen',
  SOLD = 'sold',
  RETIRED = 'retired',
}

export const RANGE_STATUSES = {
  [CALENDAR_RANGE_STATUS_TYPES.LOST_STOLEN]: 'Lost or Stolen',
  [CALENDAR_RANGE_STATUS_TYPES.SOLD]: 'Sold',
  [CALENDAR_RANGE_STATUS_TYPES.RETIRED]: 'Retired',
};

export enum MOVEMENT_WIZARD_TRIGGERS {
  SHELVED = 'Shelved',
  RIGHT_SIDE_BAR_PLUS_ICON = 'Right Side Bar Plus Icon',
  SCAN = 'Scan',
  LOCATE_ICON = 'Locate Icon',
}

export enum CONVERSION_WARNINGS {
  CHILD_COONVERTED = 'Converted items',
  CONVERTED = 'Converted',
}

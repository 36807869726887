import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dom-spinner',
  templateUrl: './dom-spinner.component.html',
  styleUrls: ['./dom-spinner.component.scss'],
})
export class DOMSpinnerComponent {
  @Input() diameter = 40;
}

export const environment = {
  production: true,
  backendURL: 'https://staging5.rolustech.com:44349',
  backendRestURL: 'rest/v11_20', // relates to version
  version: 'v11_20',
  api: {
    client_id: 'sugar',
    client_secret: '',
    grant_type: 'password',
    module: 'avid_personnels',
  },
  mqtt: {
    server: 'aryqe2hzin4f7-ats.iot.us-east-2.amazonaws.com',
    protocol: 'wss',
    port: 443,
    path: '/mqtt',
    authorizerName: 'freelanceDevAuth',
    topicPrefix: 'pfp/dev',
  },
  measurementId: 'G-M8XNKJH91Z',
  API_TIMEOUT: 5000,
};

import { Component, Input, OnInit } from '@angular/core';
import { NOTIFICATION_TYPE } from '../../../../helpers/constants';
import {
  BannerNotificationInterface,
  BannerNotificationService,
} from '../../../../../../services/banner-notification.service';

@Component({
  selector: 'app-banner-notification',
  templateUrl: './banner-notification.component.html',
  styleUrls: ['./banner-notification.component.scss'],
})
export class BannerNotificationComponent implements OnInit {
  @Input() notification!: BannerNotificationInterface | null;
  notificationType = NOTIFICATION_TYPE;
  constructor(
    private readonly bannerNotificationService: BannerNotificationService
  ) {}

  onRemoveIconClick() {
    this.bannerNotificationService.clearNotification();
  }

  onButtonClick() {
    if (this.notification) {
      const callback = this.notification.button?.callback;
      if (callback) callback();
    }
  }

  ngOnInit(): void {}
}

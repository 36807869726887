import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private readonly authService: AuthService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return await this.isLoggedIn();
  }

  /**
   * This method is to check if user is loggedIn or not
   *
   *
   * @private
   * @return {*}
   */
  private async isLoggedIn(): Promise<boolean> {
    const response = await lastValueFrom(this.authService.isLoggedIn());
    if (!response && !this.authService.isGuestRoute()) {
      this.authService.logout();
    }

    return true;
  }
}

import { ComponentType } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  ConfirmationDialogConfigInterface,
  ConfirmationDialogService,
} from 'confirmation-dialog';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { LocalStorage } from '../services/storage/local.storage.service';
import { THEME_DEFAULT } from './constants';
import { STORAGE } from './enums';
import { SoundService } from '../../../services/sound.service';
import * as moment from 'moment-timezone';

@Injectable({ providedIn: 'root' })
export class SharedHelper {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    public readonly matDialog: MatDialog,
    private readonly confirmationDialogService: ConfirmationDialogService,
    private readonly localStorage: LocalStorage,
    private readonly soundService: SoundService
  ) {}

  /**
   * This method is to set Theme
   *
   * @param [bgColor=PRIMARY_BG_COLOR]
   * @param [fontColor=PRIMARY_FONT_COLOR]
   */
  public setTheme(theme: any = THEME_DEFAULT) {
    const body = this.document.querySelector('body');
    for (const key in theme) {
      if (Object.prototype.hasOwnProperty.call(theme, key)) {
        const value = theme[key];
        body?.style.setProperty(`--${key}`, value);
      }
    }
  }

  /**
   * Opens a confirmation dialog according to passed metadata.
   *
   * @param {DialogConfigInterface} data
   * @param {string} [width=window.innerWidth < 1279.98 ? '80vw' : '25vw']
   * @return {*}
   * @memberof SharedHelper
   */
  public getConfirmation(
    data: ConfirmationDialogConfigInterface,
    width: string = window.innerWidth < 1279.98 ? '80vw' : '25vw',
    soundType?: string,
    dialogConfig?: MatDialogConfig
  ) {
    if (soundType) this.soundService.playSound(soundType);
    return this.confirmationDialogService.getConfirmation(
      data,
      width,
      dialogConfig
    );
  }

  /**
   * Opens a passed component in dialog with passed configurations.
   *
   * @template T
   * @template D
   * @param {ComponentType<T>} component
   * @param {MatDialogConfig<D>} config
   * @return {*}
   * @memberof SharedHelper
   */
  public openDialog<T, D>(
    component: ComponentType<T>,
    config: MatDialogConfig<D>,
    soundType?: string
  ): Observable<any> {
    const width = window.innerWidth < 1279.98 ? '98vw' : '';
    if (soundType) this.soundService.playSound(soundType);
    return this.matDialog
      .open(component, {
        ...config,
        width: config.width || width,
        disableClose:
          config.disableClose === false ? config.disableClose : true,
      })
      .afterClosed();
  }

  /**
   * This method is to check if user is permitted or not
   *
   * @param {string[]} [permittedRoles=[]]
   * @return {*}  {boolean}
   * @memberof SharedHelper
   */
  public ifPermitted(permittedRoles: string[] = []): boolean {
    const roles = this.localStorage.get<any>(STORAGE.USER)?.roles || [];
    return !!_.intersection(permittedRoles, roles).length;
  }

  /**
   *  Convert the snake case into camel case
   *
   * @param {object}
   * @memberof SharedHelper
   */
  snakeCaseToCamelCase(data: any) {
    const convertedObject: any = {};
    Object?.keys(data)?.forEach((key: string) => {
      convertedObject[_.camelCase(key)] = data[key];
    });
    return convertedObject;
  }

  /**
   *  Funtion to get the timestamp in seconds
   */
  getSecondsTimeStamp() {
    const date = new Date();
    return `${
      date.getUTCMonth() + 1
    }/${date.getUTCDate()}/${date.getUTCFullYear()} ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`;
  }

  /**
   *  Funtion to get the timestamp in minutes
   */
  getMinutesTimeStamp() {
    const date = new Date();
    return `${
      date.getUTCMonth() + 1
    }/${date.getUTCDate()}/${date.getUTCFullYear()} ${date.getUTCHours()}:${date.getUTCMinutes()}`;
  }

  generateDateFilter(
    range: string,
    field: string,
    startDate?: string,
    endDate?: string
  ): any {
    const apiFilters: any[] = [];

    switch (range) {
      case 'before':
        apiFilters.push({ [field]: { $lt: startDate } });
        break;
      case 'after':
        apiFilters.push({ [field]: { $gt: startDate } });
        break;
      case 'equal':
        apiFilters.push({ [field]: { $equals: startDate } });
        break;
      case 'between':
        if (endDate) {
          apiFilters.push({ [field]: { $between: [startDate, endDate] } });
        }
        break;
      default:
        // Handle other cases or default behavior
        break;
    }

    return apiFilters;
  }

  getPreviousDayDate(date: string | undefined, dateFormat: string): any {
    if(!date) {
      return ''
    } else {
      return moment(date).subtract(1, 'days')
      .format(dateFormat);
    }
  }

  public setPortalDateFormat(dateText: string): string {
    const date = new Date(dateText);
    if (!isNaN(date.getTime())) {
      return (
        date.getMonth() +
        1 +
        '/' +
        date.getDate() +
        '/' +
        date.getFullYear().toString().slice(-2)
      );
    }
    return '';
  }
}

import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { throttle } from 'lodash';

const throttleTime = 700;

@Directive({
  selector: '[infiniteScroll]',
})
export class InfiniteScrollDirective {
  @Input('threshold') threshold = 50; // Pixels from the bottom and top

  @Output() scrolledToBottom = new EventEmitter<void>();
  @Output() scrolledToTop = new EventEmitter<void>();

  private element!: HTMLElement;
  private previousScrollTop = 0;

  constructor(private readonly elementRef: ElementRef) {
    this.addScrollListener();
  }

  /**
   * Responsible to find the child element which has scroll (overflow property) in it. For that element it will
   * add an event listener for scroll event. Every time that child element is scrolled the event listener
   * function will be invoked.
   */
  private addScrollListener(): void {
    setTimeout(() => {
      const elements = this.elementRef.nativeElement.children;

      for (const element of elements) {
        const computedStyle = getComputedStyle(element);

        if (
          computedStyle.overflow === 'auto' ||
          computedStyle.overflowX === 'auto' ||
          computedStyle.overflowY === 'auto'
        ) {
          this.element = element;

          this.element.addEventListener(
            'scroll',
            throttle(this.onScroll.bind(this), throttleTime)
          );
          break;
        }
      }
    }, 0);
  }

  /**
   * Wrapper function to check whether user has scrolled to the top or bottom and emit respective event.
   */
  private onScroll(): void {
    const scrollTop = this.element.scrollTop; // to check if user is moving upwards or downwards.

    if (scrollTop > this.previousScrollTop && this.isNearBottom()) {
      this.scrolledToBottom.emit();
    } else if (scrollTop < this.previousScrollTop && this.isNearTop()) {
      this.scrolledToTop.emit();
    }

    this.previousScrollTop = scrollTop;
  }

  /**
   * Checks whether user has scrolled to the bottom.
   * @returns
   */
  private isNearBottom(): boolean {
    const scrollTop = this.element.scrollTop; // the number of pixels that an element's content is scrolled vertically.
    const scrollHeight = this.element.scrollHeight; // measurement of the height of an element's content, including content not visible on the screen due to overflow.
    const clientHeight = this.element.clientHeight; // it's the inner height of an element in pixels.

    return scrollTop + clientHeight >= scrollHeight - this.threshold;
  }

  /**
   * Checks whether user has scrolled to the top.
   * @returns
   */
  private isNearTop(): boolean {
    const scrollTop = this.element.scrollTop; // the number of pixels that an element's content is scrolled vertically.

    return scrollTop <= this.threshold;
  }

  ngOnDestroy(): void {
    this.element?.removeEventListener('scroll', this.onScroll);
  }
}

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { lastValueFrom } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class OuterAuthGuard implements CanActivate {
  constructor(private readonly authService: AuthService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return await this.isLoggedOut();
  }

  /**
   * This method is to check if user is loggedOut or not
   *
   *
   * @private
   * @return {*}
   */
  private async isLoggedOut(): Promise<boolean> {
    const response = await lastValueFrom(this.authService.isLoggedIn());

    if (response) {
      this.authService.navigateToDefaultRoute();
    }

    return true;
  }
}

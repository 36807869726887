import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiPaths } from '../modules/shared/helpers/api-paths';
import { CURRENCY_SYMBOLS } from '../modules/shared/helpers/constants';
import { AppConfiguration } from '../modules/shared/interfaces/app-config.interface';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private static appConfig: AppConfiguration;

  constructor(protected httpClient: HttpClient) {}

  /**
   * This method is get Application Configurations
   *
   *
   * @static
   * @return {*}
   */
  public static getConfig(): AppConfiguration {
    return ConfigService.appConfig;
  }

  /**
   * This method is to load app configuration
   *
   *
   * @return {*}
   */
  public async loadAppConfig(): Promise<void> {
    const data: AppConfiguration = {
      backendURL: environment.backendURL,
      backendRestURL: environment.backendRestURL,
      api: environment.api,
    };
    ConfigService.appConfig = data;
    const response = await this.getCurrenciesList();

    response.forEach((currency: any) => {
      CURRENCY_SYMBOLS[currency.id] = {
        symbol: currency.symbol,
        name: currency.name,
      };
    });
  }

  /**
   * Call api to fetch currencies list and save them in constants.
   *
   * @return {*}  {Promise<any>}
   * @memberof ConfigService
   */
  async getCurrenciesList(): Promise<any> {
    return await lastValueFrom(
      this.httpClient.get(
        `${ConfigService.getConfig()?.backendURL}/${
          ConfigService.getConfig()?.backendRestURL
        }/${ApiPaths.CURRENCY_LIST_API}`
      )
    );
  }
}

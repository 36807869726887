import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogConfigInterface } from '../confirmation-dialog-interface';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
  private onDestroy = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogConfigInterface,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {
    this.subscribeKeydownEvents();
  }

  /**
   * Subscribe to keydown events of enter and escape. Call the respective functions.
   *
   * @private
   * @memberof ConfirmInputDialogComponent
   */
  private subscribeKeydownEvents() {
    this.dialogRef
      .keydownEvents()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => {
        if (event.key === 'Enter') {
          this.closeDialog(true);
        }
        if (event.key === 'Escape') {
          this.closeDialog(false);
        }
      });
  }

  private closeDialog(optionSelected: boolean): void {
    this.dialogRef.close(optionSelected);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}

import { Component, Input } from '@angular/core';
import { NumericEventModel } from 'projects/freelancer-portal/src/app/modules/private/product-availability/models/events/numeric-event.model';
import { ProductLegendStyle } from '../../../../shared/helpers/legends-list';
import { EventRangeService } from '../../services/product-availability-event-range.service';

@Component({
  selector: 'rtl-numeric-range',
  templateUrl: './numeric-range.component.html',
  styleUrls: ['./numeric-range.component.scss'],
})
export class NumericRangeComponent {
  @Input() height: number = 0;
  @Input() width: number = 0;
  private _eventRecord!: NumericEventModel;
  public rangeStyle!: ProductLegendStyle;

  @Input() set record(value: string) {
    if (value) {
      this._eventRecord = JSON.parse(value);
      this.rangeStyle = this._eventRangeService.getRangeColors(
        this._eventRecord.name
      );
    }
  }

  get eventRecord(): NumericEventModel {
    return this._eventRecord;
  }

  constructor(private _eventRangeService: EventRangeService) {}
}

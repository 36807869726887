import { environment } from 'projects/freelancer-portal/src/environments/environment';

export class ApiPaths {
  public static GET_ENUM_VALUES = 'freelancerRegistration/getEnumValues';
  public static CUSTOM_URL = 'customPortalApi';
  public static CUSTOM_V = `${environment.version}`;
  public static EXECUTE_DEFAULT_API = `${ApiPaths.CUSTOM_URL}/executeDefaultApi`;
  public static CURRENCY_LIST_API = 'freelancerRegistration/currencyList';
  public static FREELANCER_REGISTRATION = '/freelancerRegistration';
  public static FILTER_LIST = `${ApiPaths.CUSTOM_URL}/filterList`;
  public static VALIDATE_TOKEN = `${ApiPaths.CUSTOM_URL}/validateToken`;
  public static BULK = `${ApiPaths.CUSTOM_V}/bulk`;
  public static PRODUCT_CATEGORIES = `${ApiPaths.CUSTOM_V}/ProductCategories`;
  public static QUOTE_PRODUCT_CATEGORIES = `${ApiPaths.CUSTOM_V}/Quotes/QUOTE_ID/link/product_bundles`;
  public static PING = `${ApiPaths.CUSTOM_V}/ping`;
  public static GET_ITEM_QUANTITIES = `${ApiPaths.CUSTOM_URL}/getItemQuantities`;

  public static AUTH = {
    LOGIN_ROUTE: `${ApiPaths.CUSTOM_URL}/customToken`,
    LOGOUT_ROUTE: `${ApiPaths.CUSTOM_URL}/logoutUser`,
  };

  public static FREELANCER_AVAILABILITY = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/rt_freelancer_calendar`,
  };

  public static TIME_LOG = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/rt_TimeTracking`,
  };

  public static INVOICE = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/avid_personnels`,
    FILTERED_LIST_ROUTE: `${ApiPaths.CUSTOM_V}/avid_projects`,
    UPLOAD_ROUTE: `${ApiPaths.CUSTOM_V}/rt_Invoices/temp/file/filename`,
    SEND_INVOICE_DETAILS_ROUTE: `${ApiPaths.CUSTOM_V}/rt_Invoices`,
    LINK_INVOICE_ROUTE: `${ApiPaths.BULK}`,
    RT_INVOICES: `${ApiPaths.CUSTOM_V}/rt_Invoices`,
  };

  public static JOBS = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_URL}/getCrewKanban`,
    ASSIGNED_JOBS_EXPENSES: `${ApiPaths.CUSTOM_V}/prd_project_crew/CREW_ID/link/prd_project_crew_quote_expenses_1`,
    CREW_MEMBERS: `${ApiPaths.CUSTOM_V}/avid_projects/ID/link/avid_projects_prd_project_crew`,
    CREW_MEMBERS_DATA: `${ApiPaths.CUSTOM_V}/avid_personnels`,
    CREW_MEMBERS_DETAIL: `${ApiPaths.CUSTOM_V}/prd_project_crew`,
    UPDATE_LABOR_PERSON_ITEMS: `${ApiPaths.CUSTOM_URL}/updateLaborPersonItems`,
    JOB_SITES: `${ApiPaths.CUSTOM_V}/jr_job_sites`,
    EMPLOYEES: `${ApiPaths.CUSTOM_V}/Employees`,
  };

  public static QUOTES = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/Quotes`,
    DETAILS_ROUTE: `${ApiPaths.CUSTOM_V}/Quotes/QUOTE_ID`,
    QUOTE_ROOMS: `${ApiPaths.CUSTOM_V}/Quotes/QUOTE_ID/link/quotes_qr_quote_rooms`,
    LIST_ROUTE_COUNT: `${ApiPaths.CUSTOM_V}/Quotes/count`,
  };

  public static WAREHOUSE = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/rt_Warehouse`,
    WAREHOUSE_DATA: `${ApiPaths.CUSTOM_V}/rt_Warehouse/WAREHOUSE_ID`,
    WAREHOUSE_AREAS: `${ApiPaths.CUSTOM_V}/rt_Warehouse/WAREHOUSE_ID/link/rt_warehouse_rt_areas_1`,
  };

  public static PULL_SHEETS = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_URL}/getPullsheetList`,
    COUNT: `${ApiPaths.CUSTOM_URL}/getPullsheetListCount`,
    DATA: `${ApiPaths.CUSTOM_V}/prd_pull_sheet/PULLSHEET_ID`,
    PULLSHEET_LINE_ITEMS: `${ApiPaths.CUSTOM_V}/prd_pull_sheet/PULLSHEET_ID/link/prd_pull_sheet_pullsheet_line_items_1`,
    PULLSHEET_INVENTORY_PULLED: `${ApiPaths.CUSTOM_V}/prd_pull_sheet/PULLSHEET_ID/link/prd_pull_sheet_prd_inventory_pulled_1`,
    PULLSHEET_EQUIPMENT_CARRIER: `${ApiPaths.CUSTOM_V}/prd_pull_sheet/SHEET_ID/link/ps_equipment_carrier_prd_pull_sheet`,
    PULLSHEET_SCANNING_STAGES: `${ApiPaths.CUSTOM_V}/prd_pull_sheet/enum/scanning_stage`,
    INVENTORY_DIGEST: `${ApiPaths.CUSTOM_URL}/loadInventoryCache`,
    INVENTORY_ITEM: `${ApiPaths.CUSTOM_URL}/getTemporaryInventItems/ID`,
    PULL_KIT: `${ApiPaths.CUSTOM_URL}/PullKit`,
    LIST_ROUTE_COUNT: `${ApiPaths.CUSTOM_V}/prd_pull_sheet/count`,
  };

  public static RETURN_SHEETS = {
    RETURNSHEET_EQUIPMENT_CARRIER: `${ApiPaths.CUSTOM_V}/rt_returnsheet/SHEET_ID/link/returnsheet_ps_equipment_carrier`,
    RETURNSHEET_SCANNING_STAGES: `${ApiPaths.CUSTOM_V}/prd_pull_sheet/enum/scanning_stage`,
    RETURNSHEET_LINE_ITEMS: `${ApiPaths.CUSTOM_V}/rs_line_items`,
    RETURNSHEET_RETURNED_ITEMS: `${ApiPaths.CUSTOM_V}/rs_returned_items`,
    RETURNED_ITEMS_MOVEMENT: `${ApiPaths.CUSTOM_URL}/moveRI`,
    MARK_QUOTE_MISSED: `${ApiPaths.CUSTOM_URL}/markQuoteAsMissing`,
    RETURN_LOCATIONS: `${ApiPaths.CUSTOM_V}/lm_return_locations`,
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/rt_returnsheet`,
    DETAILS_ROUTE: `${ApiPaths.CUSTOM_V}/rt_returnsheet/RS_ID`,
    HANDLE_LOST_QUANTITY: `${ApiPaths.CUSTOM_URL}/handleLostQty`,
  };

  public static RS_LINE_ITEMS = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/rs_line_items`,
    LIST_COUNT_ROUTE: `${ApiPaths.CUSTOM_V}/rs_line_items/count`,
    DETAILS_ROUTE: `${ApiPaths.CUSTOM_V}/rs_line_items/RS_LINE_ITEM_ID`,
    LINKED_RETURNED_ITEMS: `${ApiPaths.CUSTOM_V}/rs_line_items/RS_LINE_ITEM_ID/link/rs_returned_items_rs_line_items`,
  };

  public static TRUCKING = {
    EQUIPMENT_CARRIERS_LIST: `${ApiPaths.CUSTOM_V}/ps_equipment_carrier`,
    EQUIPMENT_CARRIER: `${ApiPaths.CUSTOM_URL}/getActiveEquipmentCarriers`,
    GENERATE_RLI_FOR_IN_TRANSIT_EQUIPMENT_CARRIER: `${ApiPaths.CUSTOM_URL}/generateRLIForInTransitEquipmentCarrier`,
    VEHICLES: `${ApiPaths.CUSTOM_V}/rt_Vehicles`,
  };

  public static PULLED_INVENTORY = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/prd_inventory_pulled`,
    DETAILS_ROUTE: `${ApiPaths.CUSTOM_V}/prd_inventory_pulled/PULLED_INVENTORY_ID`,
    UPDATE_QUANTITY_USED: `${ApiPaths.CUSTOM_URL}/updateQtyUsed`,
  };

  public static PULLSHEET_LINE_ITEMS = {
    CREATE_ROUTE: `${ApiPaths.CUSTOM_V}/pullsheet_line_items`,
    DETAILS_ROUTE: `${ApiPaths.CUSTOM_V}/pullsheet_line_items/PULLSHEET_LINE_ITEM_ID`,
  };

  public static JOB_LINEITEMS = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_URL}/getLaborPersonItems`,
  };
  public static EQUIPMENT = {
    SEARCH_EQUIPMENT: `${ApiPaths.CUSTOM_V}/pt_inventory`,
    INVENTORY_PULLED: `${ApiPaths.CUSTOM_V}/pt_inventory/ID/link/pt_inventory_prd_inventory_pulled_1`,
    EQUIPMENT_DETAIL: `${ApiPaths.CUSTOM_V}/pt_inventory/ID`,
    GET_UNALLOCATED_QTY: `${ApiPaths.CUSTOM_URL}/getUnAllocatedQty/ID`,
    SERIALIZED_GROUP_INDIVIDUAL_ITEMS: `${ApiPaths.CUSTOM_V}/pt_inventory/ID/link/pt_inventory_pt_inventory_6`,
    KIT_GROUP_KIT_ITEMS: `${ApiPaths.CUSTOM_V}/pt_inventory/ID/link/pt_inventory_kit_groups`,
  };

  public static COMMENT = {
    POST_COMMENT: '/postComment',
    POST_COMMENT_FOR_PROJECT: `/${ApiPaths.CUSTOM_V}/Activities`,
  };

  public static DOCUMENT = {
    GET_DOCUMENTS_FOR_EQUIPMENT: `${ApiPaths.CUSTOM_V}/MODULE_NAME/ID/link/MODULE_NAME_DOCUMENTS`,
    GET_DOWNLOAD_DOCUMENT_LINK:
      '/index.php?entryPoint=force_download&id=DOC_ID&type=Documents&token=AUTH_TOKEN',
    GET_DISPLAY_EQUIPMENT_IMAGE_LINK:
      '/index.php?entryPoint=force_download&id=DOC_ID&type=SugarFieldImage&isTempFile=true&tempName=FILE_NAME&token=AUTH_TOKEN',
    GET_DOWNLOAD_DOCUMENT_LINK_FOR_INVOICES:
      '/index.php?entryPoint=force_download&id=DOC_ID&type=rt_Invoices&token=AUTH_TOKEN',
    GET_PUBLICALLY_DISPLAY_EQUIPMENT_IMAGE_LINK: `/${ApiPaths.CUSTOM_URL}/imageLink?entryPoint=force_download&id=DOC_ID&type=SugarFieldImage&isTempFile=true&tempName=FILE_NAME`,
  };

  public static CALENDAR = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/freelancerCalendarApi/getCalendarData`,
  };

  public static EXPENSE = {
    EXPENSE_TYPES: `${ApiPaths.CUSTOM_V}/quote_expenses/enum/expense_type`,
  };

  public static SIGN_UP = {
    ADD_FREELANCER: `${ApiPaths.FREELANCER_REGISTRATION}/addFreelancer`,
    UPLOAD_DOCUMENTS: `${ApiPaths.FREELANCER_REGISTRATION}/uploadDocuments`,
    ADD_FREELANCER_SKILLS: `${ApiPaths.FREELANCER_REGISTRATION}/addFreelancerSkills`,
  };

  public static KIT = {
    PATH: `${ApiPaths.CUSTOM_V}/pt_inventory`,
    DETAILS_PATH: `${ApiPaths.CUSTOM_V}/pt_inventory/KIT_ID`,
    KIT_ACTUAL_ITEM: `${ApiPaths.CUSTOM_V}/inven_items`,
    KIT_ITEM_DETAILS_PATH: `${ApiPaths.CUSTOM_V}/inven_items/KIT_ITEM_ID`,
    RELATED_CASE_COMPARTMENTS: `${ApiPaths.CUSTOM_V}/pt_inventory/CASE_ID/link/pt_inventory_comp_compartment_1`,
    PARENT_KITS: `${ApiPaths.CUSTOM_URL}/getKitHeirarchyContext/KIT_ID`,
    CREATE_INVEN_ITEM: `${ApiPaths.CUSTOM_URL}/CreateInvenItem`,
    KIT_ITEM_ADDITIVE_QUANTITY: `${ApiPaths.CUSTOM_URL}/updateInvenItemQuantity`,
    KIT_ACTUAL_ITEM_COUNT: `${ApiPaths.CUSTOM_V}/inven_items/count`,
    FORCED_KIT_COMPLETE:`${ApiPaths.CUSTOM_URL}/forcedKitComplete`,
  };

  public static LOCATION = {
    LOCATION_GENERIC: `${ApiPaths.CUSTOM_V}/MODULE_NAME`,
    LOCATION_GENERIC_RECORD_VIEW: `${ApiPaths.CUSTOM_V}/MODULE_NAME/ID`,
  };

  public static RETURNED_ITEMS = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/rs_returned_items`,
    DETAILS_ROUTE: `${ApiPaths.CUSTOM_V}/rs_returned_items/RETURNED_ITEM_ID`,
    UNIQUE_WAREHOUSES_AREAS: `${ApiPaths.CUSTOM_V}/${ApiPaths.CUSTOM_URL}/getUniqueWarehousesAndAreasForRI`,
  };

  public static EXPENDABLE_STOCK_UPDATE = `${ApiPaths.CUSTOM_URL}/updateQtyInStockApi`;
  public static SALES_ITEM = {
    MASS_UPDATE: `${ApiPaths.CUSTOM_URL}/massUpdatePI`,
    UPLOAD_IMAGE: `${ApiPaths.CUSTOM_URL}/uploadPic`,
  };

  public static PRODUCT_CATALOG = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/ProductTemplates`,
  };

  public static LOCATION_MANAGMENTT = {
    ITEM_LOCATION: `${ApiPaths.CUSTOM_URL}/getItemLocation`,
    ITEM_STATE_ON_LOCATION: `${ApiPaths.CUSTOM_URL}/getItemStateInWarehouse`,
    ITEM_STATE_ON_SITE: `${ApiPaths.CUSTOM_URL}/getItemsOnSite`,
    ITEM_STATE_IN_LOADED: `${ApiPaths.CUSTOM_URL}/getLoadedItems`,
    ITEM_STATE_IN_UNAVAILABLE: `${ApiPaths.CUSTOM_URL}/getItemsUnavailable`,
  };

  public static HISTORY_LOG = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/lm_equipment_history/filter`,
    COUNT_ROUTE: `${ApiPaths.CUSTOM_V}/lm_equipment_history/filter/count`,
    FETCH_INVENTORY: `${ApiPaths.CUSTOM_URL}/getChildInventoryIds`,
  };

  public static LOCATION_TYPE = {
    WAREHOUSE: `${ApiPaths.CUSTOM_V}/rt_Warehouse`,
    AREA: `${ApiPaths.CUSTOM_V}/rt_Areas`,
    RACK: `${ApiPaths.CUSTOM_V}/rt_Racks`,
    SHELF: `${ApiPaths.CUSTOM_V}/rt_Shelfs`,
    BIN: `${ApiPaths.CUSTOM_V}/rt_Bins`,
  };

  public static FILTERED_QUOTE = {
    ACTIVE_QUOTE_FOR_FILTERATION: `${ApiPaths.CUSTOM_URL}/getActiveQuotesForFilteration`,
  };

  public static USERS = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/avid_personnels`,
  };

  public static MOVEMENT_API = {
    ROUTE: `${ApiPaths.CUSTOM_URL}/handleMovement`,
    FORCED_TEMPLARE_FULFILLMENT: `${ApiPaths.CUSTOM_URL}/forcedTemplateFulfillment`,
  };

  public static QPW = {
    QTYPERWAREHOUSE: `${ApiPaths.CUSTOM_V}/wh_location_quantity`,
    UPDATE_HOME_LOCATION: `${ApiPaths.CUSTOM_URL}/updateHomeLocation`,
  };

  public static PUBLIC_KIT = {
    PUBLIC_KIT_ITEMS: `${ApiPaths.CUSTOM_URL}/kitData`,
    KIT_UPDATE_INFO: `${ApiPaths.CUSTOM_URL}/getKitUpdateInfo/ID`,
  };

  public static SUPER_SERIALIZED_GROUP = {
    LINKED_SERIALIZED_GROUPS: `${ApiPaths.CUSTOM_V}/pt_inventory/SSG_ID/link/pt_inventory_super_serialized_group_pt_inventory_serialized_group`,
  };

  private static PRODUCT_AVAILABILITY_BASE = `availabilityApi`;

  public static PRODUCT_AVAILABILITY = {
    EQUIPMENT_LIST: `${ApiPaths.PRODUCT_AVAILABILITY_BASE}/getEquipmentList`,
    EQUIPMENT_LIST_COUNT: `${ApiPaths.PRODUCT_AVAILABILITY_BASE}/getEquipmentListCount`,
    GET_SUMMARY: `${ApiPaths.PRODUCT_AVAILABILITY_BASE}/getSummary`,
    GET_SERIAL_NUMBERS: `${ApiPaths.PRODUCT_AVAILABILITY_BASE}/getSerialNumbers`,
    GET_QUOTES: `${ApiPaths.PRODUCT_AVAILABILITY_BASE}/getQuotes`,
    GET_QUOTE_DETAIL: `${ApiPaths.PRODUCT_AVAILABILITY_BASE}/getQuoteDetail`,
  };

  public static AREAS = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/rt_Areas`,
    LIST_COUNT_ROUTE: `${ApiPaths.CUSTOM_V}/rt_Areas/count`,
  };

  public static AREA_BOOKINGS = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/lm_return_locations`,
    DETAILS_ROUTE: `${ApiPaths.CUSTOM_V}/lm_return_locations/AREA_BOOKING_ID`,
    AREA_CONFLICTS: `${ApiPaths.CUSTOM_V}/${ApiPaths.CUSTOM_URL}/areaConflict`,
  };

  public static TASK = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/Tasks`,
    DETAILS_ROUTE: `${ApiPaths.CUSTOM_V}/Tasks/TASK_ID`,
    LIST_ROUTE_COUNT: `${ApiPaths.CUSTOM_V}/Tasks/count`,
  };

  public static CREW = {
    LIST_ROUTE: `${ApiPaths.CUSTOM_V}/avid_personnels`,
  };

  public static CARRIER = {
    VEHICLE_LIST: `${ApiPaths.CUSTOM_V}/rt_Vehicles`,
    VEHICLE_DETAIL_ROUTE: `${ApiPaths.CUSTOM_V}/rt_Vehicles/VEHICLE_ID`,
    CARRIER_LIST_ROUTE: `${ApiPaths.CUSTOM_V}/ps_equipment_carrier`,
    CARRIER_DETAIL_ROUTE: `${ApiPaths.CUSTOM_V}/ps_equipment_carrier/CARRIER_ID`,
  };

  public static ACCOUNT = {
    ACCOUNT_LIST: `${ApiPaths.CUSTOM_V}/Accounts`,
  };
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SoundService } from 'projects/freelancer-portal/src/app/services/sound.service';
import { Subject, takeUntil } from 'rxjs';
import { SOUND_NAME } from '../../helpers/enums';
import { PingService } from '../../services/ping.service';

@Component({
  selector: 'app-offline-popup',
  templateUrl: './offline-popup.component.html',
  styleUrls: ['./offline-popup.component.scss'],
})
export class OfflinePopupComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
    },
    public dialogRef: MatDialogRef<OfflinePopupComponent>,
    private readonly pingService: PingService,
    private readonly soundService: SoundService
  ) {}

  ngOnInit(): void {
    this.soundService.playSound(SOUND_NAME.DIALOG_BOX);
    this.pingService.onlineStatus$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.soundService.playSound('success');
        this.dialogRef.close();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

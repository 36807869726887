import { Component, Input } from '@angular/core';

@Component({
  selector: 'timeline-resource-column',
  templateUrl: './timeline-resource-column.component.html',
  styleUrls: ['./timeline-resource-column.component.scss'],
})
export class TimelineResourceColumnComponent {
  public _record: {
    name: string;
    detailViewLink: string;
    webOuterLink: string;
    serialNumber: string;
    tooltip: string;
  } = { name: '', detailViewLink: '', webOuterLink: '', serialNumber: '', tooltip:'' };
  @Input() toolTip = 'View Summary';
  @Input() set record(value: string) {
    if (value) {
      this._record = JSON.parse(value);
    }
  }

  get record(): any {
    return this._record;
  }

  ngAfterViewInit(){
    if(this._record.tooltip){
      this.toolTip = this.record.tooltip
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../../../services/auth.service';
import { AppHttpService, HeaderParams } from './http-middleware.service';
import { LocalStorage } from './storage/local.storage.service';

/**
 * Generic service to handle all http requests.
 *
 * @export
 * @Class GenericService
 * @extends {AppHttpService}
 */
@Injectable({
  providedIn: 'root',
})
export class GenericService extends AppHttpService {
  /**
   * Creates an instance of GenericService.
   * @param http
   * @param override
   * @param [localStorage]
   * @param override
   * @param [authService]
   */
  constructor(
    protected http: HttpClient,
    protected override localStorage?: LocalStorage,
    protected override authService?: AuthService
  ) {
    super(http);
  }

  /**
   * Send http get request to server.
   *
   *
   * @param url
   * @param [urlParams]
   * @param [queryParams]
   * @return {*}
   */
  public get<T>(
    url: string,
    urlParams?: any[],
    queryParams?: any,
    headerParams?: Partial<HeaderParams>
  ): Observable<T> {
    if (urlParams?.length) url = this.encodeUrlParams(urlParams, url);
    return this.__get(`${url}`, queryParams, headerParams);
  }

  /**
   * Send http put request to server.
   *
   *
   * @param url
   * @param putBody
   * @param [urlParams]
   * @param [queryParams]
   * @return {*}
   */
  public put(
    url: string,
    putBody: any,
    urlParams?: any[],
    queryParams?: any,
    headerParams?: Partial<HeaderParams>
  ): Observable<any> {
    if (urlParams?.length) url = this.encodeUrlParams(urlParams, url);
    return this.__put(`${url}`, putBody, queryParams, headerParams);
  }

  /**
   * Send http post request to server
   *
   *
   * @param url
   * @param postBody
   * @param [hasBlobRes]
   * @return {*}
   */
  public post(
    url: string,
    postBody: any,
    hasBlobRes?: boolean,
    headerParams?: Partial<HeaderParams>
  ): Observable<any> {
    return this.__post(`${url}`, postBody, hasBlobRes, headerParams);
  }

  /**
   * Send http delete request to server
   *
   *
   * @param url
   * @param [urlParams]
   * @param [queryParams]
   * @return {*}
   */
  public delete(
    url: string,
    urlParams?: any[],
    queryParams?: any,
    headerParams?: Partial<HeaderParams>
  ): Observable<any> {
    if (urlParams?.length) url = this.encodeUrlParams(urlParams, url);
    return this.__delete(`${url}`, queryParams, headerParams);
  }

  /**
   * This method is to encode url parameters
   *
   *
   * @private
   * @param urlParams
   * @param url
   * @return {*}
   */
  private encodeUrlParams(urlParams: string[], url: string): string {
    urlParams?.forEach((param) => {
      url += `/${encodeURIComponent(param)}`;
    });
    return url;
  }
}

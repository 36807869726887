import {
  ComponentRef,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { DOMSpinnerComponent } from '../components/dom-spinner/dom-spinner.component';

@Directive({
  selector: '[domSpinner]',
})
export class DOMSpinnerDirective {
  private spinnerComponentRef: ComponentRef<DOMSpinnerComponent> | null = null;
  private originalElement!: TemplateRef<any>;

  @Input('spinnerDiameter') set diameter(diameter: number) {
    if (this.spinnerComponentRef)
      this.spinnerComponentRef.instance.diameter = diameter;
  }

  @Input('domSpinner') set showSpinner(showSpinner: boolean) {
    if (showSpinner) {
      this.originalElement = this.templateRef;
      this.viewContainerRef.clear();
      this.spinnerComponentRef =
        this.viewContainerRef.createComponent(DOMSpinnerComponent);
    } else {
      if (this.spinnerComponentRef) {
        this.spinnerComponentRef.destroy();
        this.spinnerComponentRef = null;
        this.viewContainerRef.clear();
        this.viewContainerRef.createEmbeddedView(this.originalElement);
      }
    }
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}
}

import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationWrapperService } from 'projects/freelancer-portal/src/app/services/notification-wrapper.service';
import { DOUBLE_SCAN_TIMEOUT } from '../../../../helpers/constants';

@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
})
export class CopyButtonComponent {
  @Input() textToCopy: string = '';
  @Input() textTitle: string = ''

  constructor(
    private clipboard: Clipboard,
    public readonly notificationService: NotificationWrapperService
  ) {}

  copyToClipboard(event: any) {
    event.stopPropagation();
    this.clipboard.copy(this.textToCopy);
    this.notificationService.success(`${this.textTitle} Copied Successfully`, {
      config: {
        duration: DOUBLE_SCAN_TIMEOUT
      },
    });
  }
}

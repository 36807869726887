import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmationDialogComponent } from './confirmation/confirmation-dialog.component';

const components = [ConfirmationDialogComponent];
const modules = [
  CommonModule,
  MatDialogModule,
  MatDividerModule,
  MatButtonModule,
  MatIconModule,
];

@NgModule({
  declarations: [...components],
  imports: [modules],
  exports: [...components],
})
export class ConfirmationDialogModule {}

import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAnchorTagWrapper]',
})
export class AnchorTagWrapperDirective {
  @Input('appAnchorTagWrapper') linkUrl!: any; // The router link or URL
  @Input() target: string = '_self'; // Target attribute for the <a> tag

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    // Ensure the directive is applied to a mat-icon
    if (this.el.nativeElement.tagName !== 'MAT-ICON') {
      console.error(
        'The anchortagWrapper directive can only be applied to mat-icon elements.'
      );
      return;
    }

    // Create the <a> element
    const anchor = this.renderer.createElement('a');
    this.renderer.setAttribute(anchor, 'href', this.linkUrl);
    this.renderer.addClass(anchor, 'icon-link-wrapper');
    this.renderer.setAttribute(anchor, 'target', this.target);

    // Move the mat-icon into the <a> element
    const matIcon = this.el.nativeElement;
    const parent = this.el.nativeElement.parentNode;
    this.renderer.removeChild(parent, matIcon);
    this.renderer.appendChild(anchor, matIcon);
    this.renderer.appendChild(parent, anchor);
  }
}

import { NumericEventModel } from '../../../product-availability/models/events/numeric-event.model';
import { Component, Input } from '@angular/core';
import { ProductLegendStyle } from '../../../../shared/helpers/legends-list';
import { EventRangeService } from '../../../product-availability/services/product-availability-event-range.service';

@Component({
  selector: 'rtl-area-booking-numeric-range',
  templateUrl: './area-booking-numeric-range.component.html',
  styleUrls: ['./area-booking-numeric-range.component.scss'],
})
export class AreaBookingNumericRangeComponent {
  @Input() height: number = 0;
  @Input() width: number = 0;
  private _eventRecord!: NumericEventModel;
  public rangeStyle!: ProductLegendStyle;
  public overlayRangeStyle!: ProductLegendStyle;

  @Input() set record(value: string) {
    if (value) {
      this._eventRecord = JSON.parse(value);
      if (this._eventRecord.qty) {
        this.rangeStyle = this._eventRangeService.getRangeColors('PULLSHEET');
        this.overlayRangeStyle =
          this._eventRangeService.getRangeColors('RETURNSHEET');
      }
    }
  }

  get eventRecord(): NumericEventModel {
    return this._eventRecord;
  }

  get childHeightPercentage(): number {
    const pullsheets = this.eventRecord.pullsheets || 0;
    const returnsheets = this.eventRecord.returnsheets || 0;
    return (returnsheets /( pullsheets + returnsheets)) * 100;
  }

  constructor(private _eventRangeService: EventRangeService) {}
}

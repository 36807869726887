import { Directive, ElementRef, Renderer2, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[stickyVisibleScrollable]', // The directive can be used with any element
})
export class StickyVisibleScrollableDirective implements OnInit {
  @Input() scrollbarWidth: string = '6px';
  @Input() scrollbarColor: string = '#909090cd';
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.applyStyles();
  }

  private applyStyles(): void {
    const el = this.el.nativeElement;
    const parentEl = el.parentElement;

    //To override _custom.scss or global scss scrollbar scss, apply styles to the parent element
    if (parentEl) {
      const globalStyleSheet = document.styleSheets[0];
      const parent = this.getParentSelector(parentEl);
      // Insert global rule dynamically for the parent element
      globalStyleSheet.insertRule(
        `
          ${parent} * {
            scrollbar-color: auto !important;
            scrollbar-width: auto;
          }
        `,
        globalStyleSheet.cssRules.length
      );

      globalStyleSheet.insertRule(
        `
          ${parent} *:hover {
            scrollbar-color: auto;
            scrollbar-width: auto;
          }
        `,
        globalStyleSheet.cssRules.length
      );

      globalStyleSheet.insertRule(
        `
          ${parent} *::-webkit-scrollbar-thumb {
            background-color: ${this.scrollbarColor} !important;
            }
        `,
        globalStyleSheet.cssRules.length
      );

      globalStyleSheet.insertRule(
        `
          ${parent} *::-webkit-scrollbar-thumb:hover {
            background-color: ${this.scrollbarColor} !important;
            }
        `,
        globalStyleSheet.cssRules.length
      );

      globalStyleSheet.insertRule(
        `
        ${el.tagName}::-webkit-scrollbar-track {
          background: #f1f1f1 !important;
        }
      `,
      globalStyleSheet.cssRules.length
      );

      globalStyleSheet.insertRule(
        `
          ${parent} *::-webkit-scrollbar {
            background-color: ${this.scrollbarColor} !important;
            width: ${this.scrollbarWidth} !important;
            }
        `,
        globalStyleSheet.cssRules.length
      );

    }

    // Apply styles to the element
    this.renderer.setStyle(el, 'overflow', 'auto');
    this.renderer.setStyle(el, 'box-sizing', 'border-box');
    this.renderer.setStyle(el, '-webkit-overflow-scrolling', 'touch');

    // Add custom styles for the scrollbar using dynamically created CSS rules
    const styleSheet = document.styleSheets[0]; // Use the first stylesheet (you could also dynamically create one if needed)

    styleSheet.insertRule(
      `
      ${el.tagName}::-webkit-scrollbar {
        width: ${this.scrollbarWidth} !important;
      }
    `,
      styleSheet.cssRules.length
    );

    styleSheet.insertRule(
      `
      ${el.tagName}::-webkit-scrollbar-thumb {
        background-color: ${this.scrollbarColor} !important;
        border-radius: 10px;
      }
    `,
      styleSheet.cssRules.length
    );

    styleSheet.insertRule(
      `
      ${el.tagName}::-webkit-scrollbar-thumb:hover {
        background-color: ${this.scrollbarColor} !important;
      }
    `,
      styleSheet.cssRules.length
    );

    styleSheet.insertRule(
      `
      ${el.tagName}::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
      }
    `,
      styleSheet.cssRules.length
    );
  }

  // Helper function to get the CSS selector of the parent element dynamically
  private getParentSelector(parentEl: HTMLElement): string {
    if (parentEl.id) {
      return `#${parentEl.id}`;
    }
    if (parentEl.classList.length > 0) {
      return `.${parentEl.classList[0]}`;
    } else {
      return parentEl.tagName.toLowerCase();
    }
  }
}

import { Injectable } from '@angular/core';
import { FractionEventModel } from '../models/events/fraction-event.model';
import {
  LegendColors,
  ProductLegendStyle,
} from '../../../shared/helpers/legends-list';
import { FRACTION_RANGE_TYPES } from '../../../shared/helpers/enums';

@Injectable({ providedIn: 'root' })
export class EventRangeService {
  getFractionRangeType(range: FractionEventModel): FRACTION_RANGE_TYPES {
    if (range.available < 0) {
      return FRACTION_RANGE_TYPES.SHORTAGE;
    } else if (range.available === 0) {
      return FRACTION_RANGE_TYPES.WARNING;
    } else if (range.available > 0 && range.total > range.available) {
      return FRACTION_RANGE_TYPES.PARTITIONED;
    } else {
      return FRACTION_RANGE_TYPES.Available;
    }
  }

  getRangeColors(eventType: string): ProductLegendStyle {
    let rangeStyle = LegendColors[eventType];
    if (!rangeStyle) {
      rangeStyle = LegendColors['any'];
    }
    return rangeStyle;
  }
}

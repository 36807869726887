import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';

import { Storage } from './storage.service';

@Injectable({ providedIn: 'root' })
export class SessionStorage extends Storage {
  constructor(sessionStorageService: SessionStorageService) {
    super(sessionStorageService);
  }
}

<ng-container *ngIf="notification">
  <div
    class="banner-header"
    [ngClass]="{
      'banner-success': notification.iconType === notificationType.SUCCESS,
      'banner-warning': notification.iconType === notificationType.WARNING,
      'banner-error': notification.iconType === notificationType.ERROR,
      'banner-info':
        notification.iconType === notificationType.INFO ||
        ![
          notificationType.INFO,
          notificationType.SUCCESS,
          notificationType.WARNING,
          notificationType.ERROR
        ].includes(notification.iconType)
    }"
  >
    <!-- Left side description wrapper -->

    <div class="description-wrapper">
      <div>
        <mat-icon [ngSwitch]="notification.iconType">
          <mat-icon
            class="icon-customize warning"
            *ngSwitchCase="notificationType.WARNING"
            >warning</mat-icon
          >
          <mat-icon
            class="icon-customize error"
            *ngSwitchCase="notificationType.ERROR"
            >cancel</mat-icon
          >
          <mat-icon
            class="icon-customize success"
            *ngSwitchCase="notificationType.SUCCESS"
            >check_circle</mat-icon
          >
          <mat-icon
            class="icon-customize info"
            *ngSwitchCase="notificationType.INFO"
            >info</mat-icon
          >

          <!-- Default icon if the iconType doesn't match any case -->
          <mat-icon class="info icon-customize" *ngSwitchDefault>info</mat-icon>
        </mat-icon>
      </div>

      <p ihPM mValue="0rem 0.25rem 0rem 0.25rem">
        {{ notification.message }}
      </p>
    </div>

    <!-- Right side button wrapper -->
    <div class="button-wrapper">
      <button
        ihPM
        mValue="0rem 1rem 0rem 0rem"
        class="icon-cursor-pointer"
        (click)="onButtonClick()"
      >
        {{ notification.button?.text }}
      </button>
      <mat-icon
        *ngIf="notification.removeIcon"
        class="icon-cursor-pointer"
        (click)="onRemoveIconClick()"
      >
        close
      </mat-icon>
    </div>
  </div>
</ng-container>

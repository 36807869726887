import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { LocalStorage } from '../services/storage/local.storage.service';
import { STORAGE } from '../helpers/enums';
import * as _ from 'lodash';

@Directive({
  selector: '[ifPermitted]',
})
export class IfPermittedDirective {
  @Input('ifPermitted') permittedRoles!: string[];
  private roles!: string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private readonly localStorage: LocalStorage
  ) {
    this.roles = this.localStorage.get<any>(STORAGE.USER)?.roles || [];
  }

  ngOnInit() {
    if (_.intersection(this.permittedRoles, this.roles).length) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}

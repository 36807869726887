import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { ProductLegendStyle } from '../../../../shared/helpers/legends-list';
import { AreaBookingModel } from '../../models/area-booking.model';
import { RTLAreaBookingsRangeRenderingService } from '../../services/area-bbokings-range-renderer.service';

@Component({
  selector: 'rtl-area-booking-range',
  templateUrl: './area-booking-range.component.html',
  styleUrls: ['./area-booking-range.component.scss'],
})
export class AreaBookingRangeComponent {
  @Input() height: number = 0;
  @Input() width: number = 0;
  private _eventRecord!: AreaBookingModel;
  public rangeStyle!: ProductLegendStyle;
  tooltipRangeTemplate: string = '';

  @Input() set record(value: string) {
    if (value) {
      this._eventRecord = JSON.parse(value);
      this.rangeStyle = this._eventRangeService.getRangeColors(
        this._eventRecord?.sheet?.type ? this._eventRecord.sheet.type : 'default'
      );
      this.tooltipRangeTemplate = this.createToolTip(this._eventRecord);
    }
  }

  get eventRecord(): AreaBookingModel {
    return this._eventRecord;
  }

  createToolTip(eventRecord: AreaBookingModel): string {
    if (!eventRecord) return '';

    let tooltipHtml = '';

    if (eventRecord.quote?.name) {
      tooltipHtml += `Quote: ${eventRecord.quote.name}`;
    }
    if (eventRecord.warehouse?.name) {
      if (eventRecord.quote?.name) {
        tooltipHtml += ' / ';
      }
      tooltipHtml += `Warehouse: ${eventRecord.warehouse.name}`;
    }
    if (eventRecord.sheet?.name) {
      if (eventRecord.quote?.name || eventRecord.warehouse?.name) {
        tooltipHtml += ' / ';
      }
      if (eventRecord.sheet.type === 'RETURNSHEET') {
        tooltipHtml += 'Return Sheet: ';
      } else if (eventRecord.sheet.type === 'PULLSHEET') {
        tooltipHtml += 'Pull Sheet: ';
      }

      tooltipHtml += `${eventRecord.sheet.name}`;
    }
    return tooltipHtml;
  }

  constructor(
    private _eventRangeService: RTLAreaBookingsRangeRenderingService
  ) {}
}

import { NgModule } from '@angular/core';
import { IfPermittedDirective } from './directives/if-permitted.directive';
import { IsMobileScreenDirective } from './directives/is-mobile-screen.directive';
import { ScreenSizeDirective } from './directives/screen-size.directive';
import { DOMSpinnerDirective } from './directives/spinner.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { NumberStylePaginatorDirective } from './directives/number-style-pagination';
import { AnchorTagWrapperDirective } from './directives/anchor-tag-wrapper.directive';
import { StickyVisibleScrollableDirective } from './directives/sticky-scrollable.directive';

// All Shared components
const components: any = [
  //Directives
  IsMobileScreenDirective,
  // TODO: Need to use this instead of isMobile
  ScreenSizeDirective,
  IfPermittedDirective,
  DOMSpinnerDirective,
  InfiniteScrollDirective,
  NumberStylePaginatorDirective,
  AnchorTagWrapperDirective,
  StickyVisibleScrollableDirective,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
})
export class SharedDirectiveModule {}

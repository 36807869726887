import { NumericEventInterface } from '../../interfaces/bryntum/event.interface';
import { EquipmentCalendarBaseEventModel } from './equipment-calendar-base-event.model';

export class NumericEventModel
  extends EquipmentCalendarBaseEventModel
  implements NumericEventInterface
{
  qty: number;
  returnsheets?: number;
  pullsheets?: number;

  constructor(event: NumericEventInterface) {
    super(event);
    this.qty = event.qty;
    this.returnsheets = event.returnsheets;
    this.pullsheets = event.pullsheets;
  }
}

<div class="dialog-header">
  <h2 mat-dialog-title>
    <div class="space-between-center">
      <span [innerHTML]="data.title || '⚠ Warning! Connection Issue'"></span>
    </div>
  </h2>
  <mat-divider></mat-divider>
</div>

<mat-dialog-content>
  <h4 innerHTML="{{ data.message }}"></h4>
</mat-dialog-content>
<mat-divider></mat-divider>

<mat-dialog-actions align="end"> </mat-dialog-actions>

import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

import { Storage } from './storage.service';

@Injectable({ providedIn: 'root' })
export class LocalStorage extends Storage {
  constructor(localStorageService: LocalStorageService) {
    super(localStorageService);
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap } from 'rxjs';
import { SharedHelper } from '../../modules/shared/helpers/shared-helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  showPublicHeader = false;
  constructor(
    private readonly sharedHelper: SharedHelper,
    public router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.sharedHelper.setTheme();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        switchMap((route) => route.data)
      )
      .subscribe((data) => {
        this.showPublicHeader = data?.['showHeader'];
      });
  }
}

import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '../public-api';
import { ConfirmationDialogConfigInterface } from './confirmation-dialog-interface';

@Injectable({ providedIn: 'root' })
export class ConfirmationDialogService {
  constructor(public readonly matDialog: MatDialog) {}

  /**
   * Opens a confirmation dialog according to passed metadata.
   *
   * @param {ConfirmationDialogConfigInterface} data
   * @param {string} [width=window.innerWidth < 1279.98 ? '80vw' : '25vw']
   * @return {*}
   * @memberof SharedHelper
   */
  public getConfirmation(
    data: ConfirmationDialogConfigInterface,
    width: string = window.innerWidth < 1279.98 ? '80vw' : '25vw',
    dialogConfig?: MatDialogConfig
  ) {
    return this.matDialog
      .open(ConfirmationDialogComponent, {
        width,
        data,
        ...dialogConfig,
      })
      .afterClosed();
  }

  /**
   * Opens a passed component in dialog with passed configurations.
   *
   * @template T
   * @template D
   * @param {ComponentType<T>} component
   * @param {MatDialogConfig<D>} config
   * @return {*}
   * @memberof SharedHelper
   */
  public openDialog<T, D>(
    component: ComponentType<T>,
    config: MatDialogConfig<D>
  ): Observable<any> {
    const width = window.innerWidth < 1279.98 ? '98vw' : '';
    return this.matDialog
      .open(component, {
        ...config,
        width: config.width || width,
        disableClose: true,
      })
      .afterClosed();
  }
}

import { EventModel } from '@bryntum/scheduler';
import { EquipmentCalendarBaseEventInterface } from '../../interfaces/bryntum/event.interface';

export class EquipmentCalendarBaseEventModel
  extends EventModel
  implements EquipmentCalendarBaseEventInterface
{
  alignment!: 'LEFT' | 'CENTER';
  constructor(event: EquipmentCalendarBaseEventInterface) {
    super({
      ...event,
      resizable: false,
      draggable: false,
    });
  }
}

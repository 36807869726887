import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[isMobileScreen]',
  exportAs: 'isMobileScreen',
})
export class IsMobileScreenDirective {
  public mobileScreen: boolean = window.innerWidth < 1279.98;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.mobileScreen = window.innerWidth < 1279.98;
  }
}

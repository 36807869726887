import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Directive,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

type ScreenSizeType = 'MOBILE' | 'TABLET' | 'LAPTOP';

@Directive({ selector: '[appScreenSize]', exportAs: 'appScreenSize' })
export class ScreenSizeDirective implements OnInit, OnDestroy {
  currentScreenSize!: ScreenSizeType;

  @Output() screenSize = new EventEmitter<ScreenSizeType>();

  private sub = new Subject();

  constructor(private readonly breakpointObserver: BreakpointObserver) {}

  ngOnInit() {
    this.observeMobileScreen();

    this.observeTabletScreen();

    this.observeLaptopScreen();
  }

  /**
   * This method is observe if screen width is for mobile
   *
   * @private
   */
  private observeMobileScreen() {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .pipe(takeUntil(this.sub))
      .subscribe((result) => {
        if (result.matches) {
          this.screenSize.emit('MOBILE');
          this.currentScreenSize = 'MOBILE';
        }
      });
  }

  /**
   * This method is observe if screen width is for tablet
   *
   * @private
   */
  private observeTabletScreen() {
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Tablet])
      .pipe(takeUntil(this.sub))
      .subscribe((result) => {
        if (result.matches) {
          this.screenSize.emit('TABLET');
          this.currentScreenSize = 'TABLET';
        }
      });
  }

  /**
   * This method is observe if screen width is for laptop
   *
   * @private
   */
  private observeLaptopScreen() {
    this.breakpointObserver
      .observe([Breakpoints.Large, Breakpoints.XLarge])
      .pipe(takeUntil(this.sub))
      .subscribe((result) => {
        if (result.matches) {
          this.screenSize.emit('LAPTOP');
          this.currentScreenSize = 'LAPTOP';
        }
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

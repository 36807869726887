import { Component, Input } from '@angular/core';
import { FractionEventModel } from 'projects/freelancer-portal/src/app/modules/private/product-availability/models/events/fraction-event.model';
import { EventRangeService } from '../../services/product-availability-event-range.service';
import { ProductLegendStyle } from '../../../../shared/helpers/legends-list';
import { FRACTION_RANGE_TYPES } from '../../../../shared/helpers/enums';

@Component({
  selector: 'rtl-fraction-range',
  templateUrl: './fraction-range.component.html',
  styleUrls: ['./fraction-range.component.scss'],
})
export class FractionRangeComponent {
  @Input() height: number = 0;
  @Input() width: number = 0;
  private _eventRecord!: FractionEventModel;
  public rangeStyle!: ProductLegendStyle;
  public overlayRangeStyle!: ProductLegendStyle;

  @Input() set record(value: string) {
    if (value) {
      this._eventRecord = JSON.parse(value);
      if (this._eventRecord.fractionRangeDivison) {
        this.rangeStyle = this._eventRangeService.getRangeColors(
          this._eventRecord.fractionRangeDivison.totalRangeType
        );
        this.overlayRangeStyle = this._eventRangeService.getRangeColors(
          this._eventRecord.fractionRangeDivison.availableRangeType
        ); 
      } else {
        const rangeType = this._eventRangeService.getFractionRangeType(
          this._eventRecord
        );
        if (rangeType === FRACTION_RANGE_TYPES.PARTITIONED) {
          this.rangeStyle = this._eventRangeService.getRangeColors(
            FRACTION_RANGE_TYPES.Available
          );
          this.overlayRangeStyle = this._eventRangeService.getRangeColors(
            FRACTION_RANGE_TYPES.PARTITIONED
          );
        } else {
          this.rangeStyle = this._eventRangeService.getRangeColors(rangeType);
        }
      }
    }
  }

  get eventRecord(): FractionEventModel {
    return this._eventRecord;
  }

  get childHeightPercentage(): number {
    let occupied = this.eventRecord.total - this.eventRecord.available;
    return (occupied / this.eventRecord.total) * 100;
  }

  constructor(private _eventRangeService: EventRangeService) {}
}

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NotificationWrapperService } from './notification-wrapper.service';

import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { NOTIFICATION_TIMEOUT } from '../modules/shared/helpers/constants';
import { HEADER_PARAMS, STORAGE } from '../modules/shared/helpers/enums';
import { PingService } from '../modules/shared/services/ping.service';
import { LocalStorage } from '../modules/shared/services/storage/local.storage.service';
import { AuthService } from './auth.service';

// TODO need to put some error codes we want to handle exclusively
const bypassErrors: string | string[] = [
  'itemAlreadyAdded',
  'SourceAlreadyMoved',
  'InsufficientDestinationQuantity',
];

declare const gtag: Function;

/**
 * This class is to intercept HTTP Request and handle errors
 *
 * @export
 * @class HttpErrorInterceptor
 */
@Injectable()
export class HttpCallInterceptor implements HttpInterceptor {
  constructor(
    private readonly notificationService: NotificationWrapperService,
    private readonly authService: AuthService,
    private readonly pingService: PingService,
    private readonly localStorage: LocalStorage
  ) {}

  /**
   * This method will intercept the HTTP request and catch error and show them into toast message
   *
   * @param request
   * @param next
   * @return {*}
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const timeOut = request.headers.get(HEADER_PARAMS.API_TIME_OUT);
    const isPing = request.headers.get(HEADER_PARAMS.IS_PING);
    return next.handle(request).pipe(
      timeout(timeOut ? Number(timeOut) : environment.API_TIMEOUT),
      catchError((error) => {
        if (
          error.status === 500 ||
          error.status === 0 ||
          error?.name === 'TimeoutError'
        ) {
          this.fireGA4Event(request, error);
        }

        this.handleUnauthorized(error);
        return throwError(() =>
          isPing ? error : this.handleErrorNotification(error)
        );
      })
    );
  }

  private fireGA4Event(request: HttpRequest<any>, error: any) {
    const userInfo = this.localStorage.get<any>(STORAGE.USER),
      date = new Date(),
      secondsTimeStamp = `${
        date.getUTCMonth() + 1
      }/${date.getUTCDate()}/${date.getUTCFullYear()} ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`,
      minutesTimeStamp = `${
        date.getUTCMonth() + 1
      }/${date.getUTCDate()}/${date.getUTCFullYear()} ${date.getUTCHours()}:${date.getUTCMinutes()}`;

    // Event Name: api_failure, Event Parameters: event_category....
    gtag('event', 'api_failure', {
      event_category: 'API_ERROR',
      request_url: request.url,
      request_body: JSON.stringify(request.body),
      http_status: error.status,
      status_text: error.statusText,
      backend_response: error.message,
      username: `${userInfo?.first_name} ${userInfo?.last_name} `,
      freelancer_id: userInfo?.id,
      api_oauth_token: request.headers.get(STORAGE.AUTHORIZATION),
      stored_oauth_token: this.localStorage.get(STORAGE.TOKEN),
      seconds_time_stamp: secondsTimeStamp,
      minutes_time_stamp: minutesTimeStamp,
    });
  }

  /**
   * This method is to handle error notification
   *
   * @private
   * @param error
   * @return {*}
   */
  private handleErrorNotification(error: any): string {
    let errorMsg = '';
    if ([0, -1, 504].includes(error.status)) {
      errorMsg = `Error: Connection Failure`;
      this.pingService.startPingingBackend();
    } else if (error.error) {
      // This is client side error
      errorMsg = `Error: ${error.error.error_message}`;
    } else if (error?.name === 'TimeoutError') {
      // This is Time out error
      errorMsg = `Error: TimeoutError,  Message: ${error.message}.
      \nThere might be issues with your internet connection or inventory server.`;
      console.error(error);
      this.pingService.startPingingBackend();
    } else {
      // This is server side error
      errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
      console.error(error);
    }
    const err = error?.error;
    if (!bypassErrors.includes(err?.error)) {
      this.notificationService.error(errorMsg, {
        config: {
          duration: NOTIFICATION_TIMEOUT,
        },
      });
      return err?.error_message;
    }
    return JSON.parse(err?.error_message);
  }

  /**
   * This method is to handle unauthorized error
   *
   * @private
   * @param error
   */
  private handleUnauthorized(error: any): void {
    if (error.statusText === 'Unauthorized' || error.status === 401) {
      this.authService.logout();
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerNotificationComponent } from './components/banner-notification/banner-notification.component';
import { AngularMaterialModule } from '../angular-material.module';
import { IHCssUtilityModule } from 'ih-ng-css-utility';

const components = [BannerNotificationComponent];
const modules = [CommonModule, AngularMaterialModule, IHCssUtilityModule];

@NgModule({
  declarations: [...components],
  imports: [...modules],
  exports: [...components],
})
export class BannerNotificationModule {}

<div class="dialog-header">
  <h2 mat-dialog-title>
    <div class="space-between-center">
      <span [innerHTML]="data.heading || 'Confirmation'"></span>
      <mat-icon
        class="icon-cursor-pointer"
        *ngIf="!data.noCloseIcon"
        mat-dialog-close
        >close</mat-icon
      >
    </div>
  </h2>
  <mat-divider></mat-divider>
</div>

<mat-dialog-content class="mat-typography">
  <h4 innerHTML="{{ data.message }}"></h4>
</mat-dialog-content>

<ng-container *ngIf="!data?.noActionButton">
  <mat-divider></mat-divider>

  <mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close]="false">
      {{ data.noButtonText || "No" }}
    </button>
    <button
      cdkFocusInitial
      mat-raised-button
      color="primary"
      [mat-dialog-close]="true"
    >
      {{ data.yesButtonText || "Yes" }}
    </button>
  </mat-dialog-actions>
</ng-container>

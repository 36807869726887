import { Injectable } from '@angular/core';
import { StringHelper } from '@bryntum/scheduler';
import { LegendColors, ProductLegendStyle } from '../../../shared/helpers/legends-list';

@Injectable({ providedIn: 'root' })
export class RTLAreaBookingsRangeRenderingService {
  static ResourceRender(record: any): string {
    const resourceRecord = JSON.stringify(record.data);
    return StringHelper.xss`<timeline-resource-column record="${resourceRecord}"></timeline-resource-column>`;
  }

  static EventRangeRndrer(
    eventRangeRecord: any,
    rendererData: any,
    resourceRecord: any
  ): string {
    const eventRange = JSON.stringify(eventRangeRecord.originalData);
    return StringHelper.xss`<rtl-area-booking-range height="${rendererData.height}" width="${rendererData.width}" record="${eventRange}"></rtl-area-booking-range>`;
  }
  
  getRangeColors(eventType: string): ProductLegendStyle {
    let rangeStyle = LegendColors[eventType];
    if (!rangeStyle) {
      rangeStyle = LegendColors['any'];
    }
    return rangeStyle;
  }
}

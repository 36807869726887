import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';

export class Storage {
  private serviceItem: LocalStorageService | SessionStorageService;

  constructor(serviceItem: LocalStorageService | SessionStorageService) {
    this.serviceItem = serviceItem;
  }

  /**
   * To Observe the change in variable from storage
   *
   *
   * @param key
   * @return {*} 
   */
  observe<T>(key: string): Observable<T> {
    return this.serviceItem.observe(key);
  }

  /**
   * Save data in storage
   *
   *
   * @param key
   * @param value
   */
  save(key: string, value: any): void {
    this.serviceItem.store(key, value);
  }

  /**
   * Save list of data in storage.
   *
   *
   * @param data
   */
  saveAll(data: Array<{ key: string; value: any }>): void {
    data.forEach((d) => {
      this.save(d.key, d.value);
    });
  }

  /**
   * Remove data in storage by key.
   *
   *
   * @param key
   */
  remove(key: string): void {
    this.serviceItem.clear(key);
  }

  /**
   * Remove data in storage by list of key.
   *
   *
   * @param data
   */
  removeAll(data: string[]): void {
    data.forEach((d) => {
      this.remove(d);
    });
  }

  /**
   * Returns data form storage by key.
   *
   *
   * @template T
   * @param key
   * @return {*} 
   */
  get<T>(key: string): T {
    return this.serviceItem.retrieve(key) as T;
  }

  /**
   * Returns data form storage by list of key.
   *
   *
   * @param data
   */
  getAll(data: string[]): any[] {
    const result: any[] = []
    data.forEach((d) => {
      result.push(this.get<any>(d));
    });
    return result;
  }

  /**
   * Returns true if value exist.
   *
   *
   * @param key
   * @return {*} 
   */
  has(key: string): boolean {
    return !(this.get(key) === null || this.get(key) === undefined);
  }

  /**
   * Clear Storage
   *
   *
   */
  public clear(): void {
    this.serviceItem.clear();
  }
}

import { AreaBookingNumericRangeComponent } from './modules/private/area-bookings/components/area-booking-numeric-range/area-booking-numeric-range.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IHNotificationModule } from 'ih-ng-notification';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/base/app.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SharedDirectiveModule } from './modules/shared/directives.module';
import { AngularMaterialModule } from './modules/shared/modules/angular-material.module';
import { BannerNotificationModule } from './modules/shared/modules/banner-notification/banner-notification.module';
import { ConfigService } from './services/app-config.service';
import { HttpCallInterceptor } from './services/http-call.interceptor.service';
import { LoaderInterceptor } from './services/loader.interceptor.service';
import { TimelineResourceColumnComponent } from './modules/shared/modules/resource-timeline/components/timeline-resource-column/timeline-resource-column.component';
import { createCustomElement } from '@angular/elements';
import { FractionRangeComponent } from './modules/private/product-availability/components/fraction-range/fraction-range.component';
import { NumericRangeComponent } from './modules/private/product-availability/components/numeric-range/numeric-range.component';
import { TextRangeComponent } from './modules/shared/modules/resource-timeline/components/text-range/text-range.component';
import { AreaBookingRangeComponent } from './modules/private/area-bookings/components/area-booking-range/area-booking-range.component';

const basicComponents = [AppComponent, SpinnerComponent];

const modules = [
  CommonModule,
  AppRoutingModule,
  BrowserModule,
  HttpClientModule,
  BrowserAnimationsModule,
  MatProgressSpinnerModule,
  AngularMaterialModule,
  SharedDirectiveModule,
  MatDialogModule,
  BannerNotificationModule,

  NgxWebstorageModule.forRoot({
    prefix: '',
    separator: '',
    caseSensitive: true,
  }),
  IHNotificationModule,
  NgxGoogleAnalyticsModule.forRoot(environment.measurementId),
  NgxGoogleAnalyticsRouterModule,
];

/**
 * This function is to initialize Configuration loader function
 *
 *
 * @param appConfig
 * @return {*}
 */
const appInitializerFn = (appConfig: ConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

const providers = [
  // Providing HTTP_INTERCEPTORS here
  { provide: HTTP_INTERCEPTORS, useClass: HttpCallInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFn,
    multi: true,
    deps: [ConfigService],
  },
];

@NgModule({
  declarations: [...basicComponents],
  imports: [...modules],
  providers: [...providers],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    // convert Angular Component to Custom Element and register it with browser
    customElements.define(
      'timeline-resource-column',
      createCustomElement(TimelineResourceColumnComponent, { injector })
    );
    customElements.define(
      'rtl-fraction-range',
      createCustomElement(FractionRangeComponent, { injector })
    );
    customElements.define(
      'rtl-numeric-range',
      createCustomElement(NumericRangeComponent, { injector })
    );
    customElements.define(
      'rtl-text-range',
      createCustomElement(TextRangeComponent, { injector })
    );
    customElements.define(
      'rtl-area-booking-range',
      createCustomElement(AreaBookingRangeComponent, { injector })
    );
    customElements.define(
      'rtl-area-booking-numeric-range',
      createCustomElement(AreaBookingNumericRangeComponent, { injector })
    );
  }
}

import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { LocalStorage } from 'projects/freelancer-portal/src/app/modules/shared/services/storage/local.storage.service';
import { userProfileInterface } from '../interfaces/user-profile.interface';
import { STORAGE } from '../modules/shared/helpers/enums';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  private userProfile: userProfileInterface | undefined;

  constructor(private readonly localStorage: LocalStorage) {}

  saveUserData(userData: any): void {
    this.localStorage.save(STORAGE.TOKEN, userData.token);
    userData.roles = userData.type
      ? userData.type.split('^').filter((role: string) => role && role !== ',')
      : null;
    this.localStorage.save(STORAGE.USER, userData);
    this.userProfile = this.manageUserObject(userData);
  }

  getUserData(): userProfileInterface {
    return (
      this.userProfile ??
      this.manageUserObject(this.localStorage.get<any>(STORAGE.USER))
    );
  }

  private manageUserObject(userData: any): userProfileInterface {
    const formatedObject: any = {};
    if (userData) {
      Object.keys(userData).forEach((keys: any) => {
        formatedObject[_.camelCase(keys)] = userData[keys] || '';
      });
    }
    return formatedObject;
  }

  public resetUserData() {
    this.userProfile = undefined;
  }

  public hasToken(): boolean {
    return !!this.getUserData()?.token;
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from '../../../services/app-config.service';
import { AuthService } from '../../../services/auth.service';
import { HEADER_PARAMS, STORAGE } from '../helpers/enums';
import { CustomHttpParamEncoder } from '../helpers/http-params-encoder';
import { RequestConfig } from '../interfaces/request-config.interface';
import { LocalStorage } from './storage/local.storage.service';

export interface HeaderParams {
  showNoLoader: boolean;
  apiTimeOut: number;
  isPing: boolean;
}

@Injectable()
export class AppHttpService {
  private backend = ConfigService.getConfig();
  private reqConfig: RequestConfig;

  /**
   * Creates an instance of AppHttpService.
   * @param httpClient
   * @param [localStorage]
   * @param [authService]
   */
  constructor(
    protected httpClient: HttpClient,
    protected localStorage?: LocalStorage,
    protected authService?: AuthService
  ) {
    this.reqConfig = {
      headers: new HttpHeaders(),
      params: new HttpParams({ encoder: new CustomHttpParamEncoder() }),
    };
  }

  /**
   * This function is to append Token & Language with the header of API call
   *
   *
   * @private
   */
  private appendHeader(headerParams?: Partial<HeaderParams>): void {
    this.reqConfig = {
      headers: new HttpHeaders(),
      params: new HttpParams({ encoder: new CustomHttpParamEncoder() }),
    };
    this.appendTokenInHeader();

    this.appendShowLoaderInHeader(headerParams?.showNoLoader);
    this.appendApiTimeOutInHeader(headerParams?.apiTimeOut);
    this.appendIsPingInHeader(headerParams?.isPing);
  }

  private appendIsPingInHeader(isPing = false) {
    isPing &&
      (this.reqConfig.headers = this.reqConfig.headers?.append(
        HEADER_PARAMS.IS_PING,
        isPing.toString()
      ));
  }
  private appendShowLoaderInHeader(startLoader: boolean = true): void {
    !startLoader &&
      (this.reqConfig.headers = this.reqConfig.headers?.append(
        HEADER_PARAMS.SHOW_NO_LOADER,
        startLoader.toString()
      ));
  }

  private appendApiTimeOutInHeader(apiTimeOut: number = 60000): void {
    if (apiTimeOut) {
      this.reqConfig.headers = this.reqConfig.headers?.append(
        HEADER_PARAMS.API_TIME_OUT,
        apiTimeOut.toString()
      );
    }
  }

  /**
   * This function is to append Token with the header of API call
   *
   *
   * @private
   */
  private appendTokenInHeader() {
    let token: string | undefined = this.localStorage?.get(STORAGE.TOKEN);
    // token = `${token}`;
    if (token && !this.reqConfig?.headers?.has(STORAGE.TOKEN)) {
      this.reqConfig.headers = this.reqConfig?.headers?.append(
        STORAGE.AUTHORIZATION,
        token
      );
    }
  }

  /**
   * This function is to append query parameters
   *
   *
   * @private
   * @param params
   */
  private appendParams(params: any): void {
    this.reqConfig.params = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    });
    Object.keys(params).forEach((key) => {
      this.reqConfig.params = this.reqConfig?.params?.append(key, params[key]);
    });
  }

  /**
   * This Wrapper is for GET Rest API call
   *
   *
   * @protected
   * @param url
   * @param [params]
   * @return {*}
   */
  protected __get<T>(
    url: string,
    params?: any,
    headerParams?: Partial<HeaderParams>
  ): Observable<T> {
    this.appendHeader(headerParams);
    return this.getCall<T>(url, params);
  }

  /**
   * This method is for GET Rest API call
   *
   *
   * @private
   * @param url
   * @param [params]
   * @return {*}
   */
  private getCall<T>(url: string, params?: any): Observable<T> {
    if (params) {
      this.appendParams(params);
    }
    return this.httpClient.get<T>(
      `${this.backend?.backendURL}/${this.backend?.backendRestURL}/${url}`,
      this.reqConfig
    );
  }

  /**
   * This Wrapper is for PUT Rest API call
   *
   *
   * @protected
   * @param url
   * @param putBody
   * @param [params]
   * @return {*}
   */
  protected __put<T>(
    url: string,
    putBody: any,
    params?: any,
    headerParams?: Partial<HeaderParams>
  ): Observable<T> {
    this.appendHeader(headerParams);
    return this.putCall<T>(url, putBody, params);
  }

  /**
   * This method is for PUT Rest API call
   *
   *
   * @private
   * @param url
   * @param putBody
   * @param [params]
   * @return {*}
   */
  private putCall<T>(url: string, putBody: any, params?: any): Observable<T> {
    if (params) {
      this.appendParams(params);
    }
    return this.httpClient.put<T>(
      `${this.backend?.backendURL}/${this.backend?.backendRestURL}/${url}`,
      putBody,
      this.reqConfig
    );
  }

  /**
   * This Wrapper is for POST Rest API call
   *
   *
   * @protected
   * @param url
   * @param postBody
   * @param [hasBlobRes]
   * @return {*}
   */
  protected __post<T>(
    url: string,
    postBody: any,
    hasBlobRes?: boolean,
    headerParams?: Partial<HeaderParams>
  ): Observable<T> {
    this.appendHeader(headerParams);
    return this.postCall<T>(url, postBody, hasBlobRes);
  }

  /**
   * This method is for POST Rest API call
   *
   *
   * @private
   * @param url
   * @param postBody
   * @param [hasBlobRes]
   * @return {*}
   */
  private postCall<T>(
    url: string,
    postBody: any,
    hasBlobRes?: boolean
  ): Observable<T> {
    const postConfig = { ...this.reqConfig };
    if (hasBlobRes) {
      postConfig.responseType = 'blob' as 'json';
    }
    return this.httpClient.post<T>(
      `${this.backend?.backendURL}/${this.backend?.backendRestURL}/${url}`,
      postBody,
      postConfig
    );
  }

  /**
   * This Wrapper is for DELETE Rest API call
   *
   *
   * @protected
   * @param url
   * @param [params]
   * @return {*}
   */
  protected __delete<T>(
    url: string,
    params?: any,
    headerParams?: Partial<HeaderParams>
  ): Observable<T> {
    this.appendHeader(headerParams);
    return this.deleteCall<T>(url, params);
  }

  /**
   * This method is for DELETE Rest API call
   *
   *
   * @private
   * @param url
   * @param [params]
   * @return {*}
   */
  private deleteCall<T>(url: string, params?: any): Observable<T> {
    if (params) {
      this.appendParams(params);
    }
    return this.httpClient.delete<T>(
      `${this.backend?.backendURL}/${this.backend?.backendRestURL}/${url}`,
      this.reqConfig
    );
  }
}

import { Injectable } from '@angular/core';
import { IHNotificationService } from 'ih-ng-notification';
import { SOUND_NAME } from '../modules/shared/helpers/enums';
import { NotificationConfigurationInterface } from '../modules/shared/interfaces/notification-config.interface';
import { SoundService } from './sound.service';

/**
 * @description This service is wrapper to ih-ng-notification service
 * It configures the project specific notification configurations to
 * the library functions
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationWrapperService {
  constructor(
    private readonly notificationService: IHNotificationService,
    private readonly soundService: SoundService
  ) {}

  /**
   * @description This method is to show notification of error type
   */
  public error(
    message: string,
    notificationConfig: NotificationConfigurationInterface = {}
  ): void {
    const { heading, closeable, config, playSound } = notificationConfig;
    playSound && this.soundService.playSound(SOUND_NAME.ERROR);
    this.notificationService.error(
      message,
      heading ?? '',
      closeable ?? true,
      config ?? {}
    );
  }

  /**
   * @description This method is to show notification of warning type
   */
  public warning(
    message: string,
    notificationConfig: NotificationConfigurationInterface = {}
  ): void {
    const { heading, closeable, config, playSound } = notificationConfig;
    playSound && this.soundService.playSound(SOUND_NAME.WARNING);
    this.notificationService.warning(
      message,
      heading ?? '',
      closeable ?? true,
      config ?? {}
    );
  }

  /**
   * @description This method is to show notification of success type
   */
  public success(
    message: string,
    notificationConfig: NotificationConfigurationInterface = {}
  ): void {
    const { heading, closeable, config, playSound } = notificationConfig;
    playSound && this.soundService.playSound(SOUND_NAME.SUCCESS);
    this.notificationService.success(
      message,
      heading ?? '',
      closeable ?? true,
      config ?? {}
    );
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BannerNotificationService {
  private bannerNotification =
    new Subject<BannerNotificationInterface | null>();
  public bannerNotification$ = this.bannerNotification.asObservable();
  constructor() {}

  showNotification(notification: BannerNotificationInterface) {
    this.bannerNotification.next(notification);
  }

  clearNotification() {
    this.bannerNotification.next(null);
  }
}

export interface BannerNotificationInterface {
  componentName: string;
  iconType: string;
  message: string;
  button?: { text: string; callback: () => void };
  removeIcon?: boolean;
}

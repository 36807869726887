import { PS_SCANNING_STAGES } from 'projects/freelancer-portal/src/app/modules/shared/helpers/enums';
import { OptionType } from 'form-renderer';
import { PaginationConfigurations } from '../interfaces/data-table-interface';
import { ThemeInterface } from '../interfaces/theme.interface';
import {
  STORAGE,
  USER_ROLES,
  RS_SCANNING_STAGES,
  EQUIPMENT_PURPOSE,
} from './enums';

export const CONSTANTS = {
  KEYS_TO_REMOVE: [
    STORAGE.TOKEN,
    STORAGE.USER,
    STORAGE.USERCONFIG,
    STORAGE.RETURN_SHEET_DRAWER,
    STORAGE.PULL_SHEET_DRAWER,
    STORAGE.KIT_BUILDING_DRAWER,
    STORAGE.NAVIGATION_BAR_EXPANDED,
    STORAGE.KIT_BUILDING_ACTION,
    STORAGE.RETURN_SHEET_DRAWER,
  ],
};

// Default theme configuration
export const THEME_DEFAULT: ThemeInterface = {
  'font-family':
    'Inter var,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji"',
  'primary-bg-color': '#29557d',
  'primary-font-color': '#ffffff',
  'secondary-bg-color': '#e6e6e66e',
  'secondary-font-color': '#000000',
  'primary-button-bg-color': '#29557d',
  'primary-button-font-color': '#ffffff',
  'secondary-button-bg-color': '#e1e1e1',
  'secondary-button-font-color': '#000000',
};

export const SHEET_COLORS = {
  complete: '#c6efc8', //green if required quantity fulfilled
  incomplete: '#D9D9D9', //grey if required quantity incomplete
  overage: '#f2b5b2', //red if overage in line item. or pulled item kit not complete.
  pulled: '#DBEBFF', //blue item pulled.
  extras: '#D9D9D9', // extras shown in default color.
};

export const PAGINATION_CONFIGURATIONS: PaginationConfigurations = {
  applyPagination: true,
  disabled: false,
  hidePageSize: false,
  pageSize: 10,
  pageSizeOptions: [5, 10, 25, 100],
  showFirstLastButtons: true,
};
export const CUSTOM_PAGINATION_CONFIGURATIONS: PaginationConfigurations = {
  applyPagination: true,
  disabled: false,
  hidePageSize: false,
  pageSize: 50,
  pageSizeOptions: [25, 50, 100, 250],
  showFirstLastButtons: true,
};

// stores currency symbols and their names against ids.
export const CURRENCY_SYMBOLS: { [key: string]: any } = {};

export const JOB_TYPE: { [key: string]: string } = {
  perDay: 'Daily',
  perHour: 'Hourly',
};

export const JOB_TYPE_KEYS = {
  PER_DAY: 'perDay',
  PER_HOUR: 'perHour',
};

export const ROUTE_VIA_USER_ROLES: {
  [key: string]: { label: string; roles: string[] };
} = {
  invoices: {
    label: 'Invoices',
    roles: [USER_ROLES.FREELANCER, USER_ROLES.STAFF],
  },
  'time-log': {
    label: 'Time logs',
    roles: [USER_ROLES.FREELANCER, USER_ROLES.STAFF],
  },
  jobs: {
    label: 'Jobs',
    roles: [USER_ROLES.FREELANCER, USER_ROLES.STAFF],
  },
  'job-schedule': {
    label: 'Job Schedule',
    roles: [USER_ROLES.FREELANCER, USER_ROLES.STAFF],
  },
  'freelancer-availability': {
    label: 'Freelancer Availability',
    roles: [USER_ROLES.FREELANCER, USER_ROLES.STAFF],
  },

  equipment: {
    label: 'Equipment',
    roles: [
      USER_ROLES.FREELANCER,
      USER_ROLES.STAFF,
      USER_ROLES.WAREHOUSE_WORKER,
      USER_ROLES.WAREHOUSE_MANAGER,
    ],
  },
  search: {
    label: 'Search',
    roles: [
      USER_ROLES.FREELANCER,
      USER_ROLES.STAFF,
      USER_ROLES.WAREHOUSE_WORKER,
      USER_ROLES.WAREHOUSE_MANAGER,
    ],
  },

  calendar: {
    label: 'Calendar',
    roles: [USER_ROLES.FREELANCER, USER_ROLES.STAFF],
  },
  'user-profile': {
    label: 'User Profile',
    roles: [USER_ROLES.FREELANCER, USER_ROLES.STAFF],
  },
  sheet: {
    label: 'Sheets',
    roles: [USER_ROLES.WAREHOUSE_WORKER, USER_ROLES.WAREHOUSE_MANAGER],
  },
  kit: {
    label: 'Kits',
    roles: [USER_ROLES.WAREHOUSE_WORKER, USER_ROLES.WAREHOUSE_MANAGER],
  },
  trucking: {
    label: 'Trucks',
    roles: [USER_ROLES.DRIVER, USER_ROLES.WAREHOUSE_MANAGER],
  },
  public_kit_view: {
    label: '',
    roles: [
      USER_ROLES.WAREHOUSE_WORKER,
      USER_ROLES.WAREHOUSE_MANAGER,
      USER_ROLES.FREELANCER,
      USER_ROLES.STAFF,
      USER_ROLES.DRIVER,
    ],
  },
  'inventory-available': {
    label: 'Inventory Availablility',
    roles: [USER_ROLES.WAREHOUSE_WORKER, USER_ROLES.WAREHOUSE_MANAGER],
  },
  'area-bookings': {
    label: 'Area Bookings',
    roles: [USER_ROLES.LOGISTICS_MANAGER, USER_ROLES.WAREHOUSE_MANAGER],
  },
  'job-dashboard': {
    label: 'Job Dashboard',
    roles: [USER_ROLES.LOGISTICS_MANAGER, USER_ROLES.WAREHOUSE_MANAGER],
  },
  'warehouse-worker-dashboard': {
    label: 'Warehouse Worker Dashboard',
    roles: [USER_ROLES.WAREHOUSE_WORKER],
  },
  warehouse: {
    label: 'warehouse',
    roles: [USER_ROLES.WAREHOUSE_MANAGER, USER_ROLES.WAREHOUSE_WORKER],
  },
};

export const CURRENT_LOCATION_FIELD_NAMES: { [key: string]: string } = {
  WAREHOUSE: 'current_warehouse_id',
  AREA: 'current_area_id',
  RACK: 'current_rack_id',
  SHELF: 'current_shelf_id',
  BIN: 'current_bin_id',
};
export const HOME_LOCATION_FIELD_NAMES: { [key: string]: string } = {
  WAREHOUSE: 'warehouse_id',
  AREA: 'home_area_id',
  RACK: 'home_rack_id',
  SHELF: 'home_shelf_id',
  BIN: 'home_bin_id',
};

export const binFields = [
  'id',
  'name',
  'shelf_id',
  'shelf_name',
  'rack_id',
  'rack_name',
  'area_id',
  'area_name',
];

export const shelfFields = [
  'id',
  'name',
  'rack_id',
  'rack_name',
  'area_id',
  'area_name',
];
export const rackFields = ['id', 'name', 'area_id', 'area_name'];

export const NAV_LIST_ROUTE_VIA_USER_ROLES: { [key: string]: any[] } = {
  '/job-schedule': [USER_ROLES.FREELANCER, USER_ROLES.STAFF],
  '/time-log': [USER_ROLES.FREELANCER, USER_ROLES.STAFF],
  '/invoices': [USER_ROLES.FREELANCER, USER_ROLES.STAFF],
  undefined: [USER_ROLES.FREELANCER, USER_ROLES.STAFF],
  '/jobs/assigned-jobs': [USER_ROLES.FREELANCER, USER_ROLES.STAFF],
  '/jobs/pending-jobs': [USER_ROLES.FREELANCER, USER_ROLES.STAFF],
  '/calendar': [USER_ROLES.FREELANCER, USER_ROLES.STAFF],
  '/inventory-available': [
    USER_ROLES.WAREHOUSE_WORKER,
    USER_ROLES.WAREHOUSE_MANAGER,
  ],
  '/area-bookings': [
    USER_ROLES.LOGISTICS_MANAGER,
    USER_ROLES.WAREHOUSE_MANAGER,
  ],
  '/job-dashboard': [
    USER_ROLES.LOGISTICS_MANAGER,
    USER_ROLES.WAREHOUSE_MANAGER,
  ],
  '/warehouse-worker-dashboard': [USER_ROLES.WAREHOUSE_WORKER],
  '/freelancer-availability': [USER_ROLES.FREELANCER, USER_ROLES.STAFF],

  '/search': [
    USER_ROLES.FREELANCER,
    USER_ROLES.STAFF,
    USER_ROLES.WAREHOUSE_WORKER,
    USER_ROLES.WAREHOUSE_MANAGER,
  ],
  '/sheet/pull-sheet': [
    USER_ROLES.WAREHOUSE_WORKER,
    USER_ROLES.WAREHOUSE_MANAGER,
  ],
  '/sheet/return-sheet': [
    USER_ROLES.WAREHOUSE_WORKER,
    USER_ROLES.WAREHOUSE_MANAGER,
  ],
  '/trucking/unload-truck': [USER_ROLES.DRIVER, USER_ROLES.WAREHOUSE_MANAGER],
  '/trucking': [USER_ROLES.DRIVER, USER_ROLES.WAREHOUSE_MANAGER],
  '/trucking/arrived-at-warehouse': [
    USER_ROLES.DRIVER,
    USER_ROLES.WAREHOUSE_MANAGER,
  ],
  '/trucking/leave-warehouse': [
    USER_ROLES.DRIVER,
    USER_ROLES.WAREHOUSE_MANAGER,
  ],
};

export const DOM_PERMISSIONS = {
  CURRENT_LOCATION: [USER_ROLES.WAREHOUSE_WORKER, USER_ROLES.WAREHOUSE_MANAGER],
  INVENTORY_CALENDER: [
    USER_ROLES.WAREHOUSE_WORKER,
    USER_ROLES.WAREHOUSE_MANAGER,
  ],
  NOTIFICATION_COMPONENT: [
    USER_ROLES.WAREHOUSE_WORKER,
    USER_ROLES.WAREHOUSE_MANAGER,
  ],
  HOME_LOCATION: [USER_ROLES.WAREHOUSE_MANAGER],
  ITEM_LOCATION: [USER_ROLES.WAREHOUSE_MANAGER],
  REGISTER_EQUIPMENT_SCAN: [
    USER_ROLES.WAREHOUSE_WORKER,
    USER_ROLES.STAFF,
    USER_ROLES.WAREHOUSE_MANAGER,
    USER_ROLES.FREELANCER,
  ],
};

export const NEW_UNSERIALIZED_SOURCE_OPTION: OptionType = {
  value: 'inventory',
  label: 'From Inventory',
};

export const FROM_PULLED_ITEMS_OPTION: OptionType = {
  value: 'From Pulled Item',
  label: 'From Pull Sheet',
};

export const RETURN_OR_NEW_ITEMS_VALUE = 'From Returned Item';

export const FROM_RETURNED_ITEMS_OPTION: OptionType = {
  value: RETURN_OR_NEW_ITEMS_VALUE,
  label: 'From Return Sheet',
};
export const FROM_NEW_OPTION: OptionType = {
  value: RETURN_OR_NEW_ITEMS_VALUE,
  label: 'Add New',
};

export const SUBSCRIPTION_PERMISSION = {
  PULL_SHEET_NOTIFICATION: [
    USER_ROLES.WAREHOUSE_WORKER,
    USER_ROLES.WAREHOUSE_MANAGER,
  ],
};

export const SALE_ITEM_DOM_PERMISSION = {
  UNAPPRROVED_PLI: [USER_ROLES.WAREHOUSE_MANAGER],
};

export const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const SORT_STATES = {
  DEFAULT: '',
  ASC: 'asc',
  DESC: 'desc',
};

export const TEMPORARY_SCANNING_STAGES = [
  PS_SCANNING_STAGES.RESERVED,
  PS_SCANNING_STAGES.PREPPING,
  PS_SCANNING_STAGES.PULLED,
  PS_SCANNING_STAGES.LOADED,
  RS_SCANNING_STAGES.CHECKED_IN,
  RS_SCANNING_STAGES.ON_SITE,
] as string[];

export const VIRTUAL_TEMPLATE = 'virtual';
export const IMAGE_EXT_FORMATS = 'png jpg jpeg PNG JPG JPEG';

export const NOTIFICATION_TIMEOUT = 2500;
export const SCAN_TIMEOUT = 30;
export const BARCODE_MIN_LENGTH = 4;
export const SERIAL_NUMBER_LENGTH = 8;
export const DOUBLE_SCAN_TIMEOUT = 1000;
export const MAXIMUM_NUMBER = 999999;
export const SERIAL_NUMBER_SEARCH_DEBOUNCE = 2000;
export const PRODUCT_CATALOG_SEARCH_DEBOUNCE = 1500;
export const PRODUCT_CATALOG_MIN_SEARCH_LENGTH = 3;
export const DIGEST_LOAD_TIMEOUT = 1000;

export const MOVEMENT_WIZARD_DIMENSIONS = {
  minWidth: '85vw',
  maxWidth: '90vw',
  height: 'fit-content',
};

/**
 * the following fields are required by BE in order to override the default call
 * to send back is_intact field (if exists)
 */
export const IS_INTACT_FIELDS: string[] = [
  'is_intact',
  'parent_id',
  'purpose',
  'scanning_stage',
];

export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
};

export const GuestRoutes = ['public_kit_view'];

export const authorizedPublicRoutes = ['warehouse'];

export const equipmentPurposeLabels = {
  [EQUIPMENT_PURPOSE.INDIVIDUAL_ITEM]: 'Individual item',
  [EQUIPMENT_PURPOSE.GROUP]: 'Group',
  [EQUIPMENT_PURPOSE.GROUP_ITEM]: 'Group item',
  [EQUIPMENT_PURPOSE.CASE]: 'Case',
  [EQUIPMENT_PURPOSE.KIT]: 'Kit',
  [EQUIPMENT_PURPOSE.KIT_GROUP]: 'Kit group',
  [EQUIPMENT_PURPOSE.SERIALIZED_GROUPS]: 'Serialized groups',
  [EQUIPMENT_PURPOSE.EXPENDABLE]: 'Expendable',
  [EQUIPMENT_PURPOSE.STANDARD_SALE_ITEM]: 'Standard sale item',
  [EQUIPMENT_PURPOSE.SUPER_SERIALIZED_GROUP]: 'Super serialized group',
};

export enum Titles {
  PullsheetsList = 'Pullsheets List',
  PullsheetDetail = 'Pullsheet - PullsheetName',
  ReturnsheetsList = 'Returnsheets List',
  ReturnsheetDetail = 'Returnsheet - ReturnsheetName',
  JobsList = 'Jobs List',
  JobDetail = 'Job # QuoteNumber',
  KitBuilding = 'Building Kit - KitName',
  PublicKit = 'Kit - KitName',
  WarehouseDashboard = 'TODO List',
  EquipmentAvailability = 'Equipments Availability (startdate)',
  InventoryDetailAvailability = 'Availability - EquipmentName',
  AreaBookings = 'Area Bookings (startdate)',
  JobSchedule = 'Job Schedule',
  AssignedJobs = 'Assigned Jobs',
  PendingJobs = 'Pending Jobs',
  TimeLog = 'Time Log',
  Invoices = 'Invoices',
  Calendar = 'Calendar',
  FreelancerAvailability = 'Freelancer Availability',
  EquipmentSearch = 'Search Equipment',
  EquipmentDetail = 'Equipment - EquipmentName',
  Trucking = 'Trucking',
  TruckingList = 'Trucking List',
}

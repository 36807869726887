import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from './user-profile.service';

import { lastValueFrom, Observable } from 'rxjs';

import { Location } from '@angular/common';
import * as _ from 'lodash';
import { ApiPaths } from '../modules/shared/helpers/api-paths';
import {
  CONSTANTS,
  GuestRoutes,
  ROUTE_VIA_USER_ROLES,
} from '../modules/shared/helpers/constants';
import { STORAGE, USER_ROLES } from '../modules/shared/helpers/enums';
import { GenericService } from '../modules/shared/services/generic.service';
import { LocalStorage } from '../modules/shared/services/storage/local.storage.service';
import { SessionStorage } from '../modules/shared/services/storage/session.storage.service';
import { ConfigService } from './app-config.service';
import { NotificationWrapperService } from './notification-wrapper.service';
import { EncryptionService } from './encryption.service';
import { environment } from '../../environments/environment.dev';
import * as base64 from 'base-64';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private config = ConfigService.getConfig();

  constructor(
    private readonly http: HttpClient,
    private readonly localStorage: LocalStorage,
    private readonly sessionStorage: SessionStorage,
    private readonly router: Router,
    private readonly genericService: GenericService,
    private location: Location,
    private readonly userProfileService: UserProfileService,
    private readonly notificationService: NotificationWrapperService,
    private readonly encryptionService: EncryptionService
  ) {}

  private readonly DEFAULT_ROUTES = new Map([
    [USER_ROLES.WAREHOUSE_MANAGER, '/job-dashboard'],
    [USER_ROLES.LOGISTICS_MANAGER, '/job-dashboard'],
    [USER_ROLES.FREELANCER, '/job-schedule'],
    [USER_ROLES.STAFF, '/job-schedule'],
    [USER_ROLES.WAREHOUSE_WORKER, '/sheet/pull-sheet'],
    [USER_ROLES.DRIVER, '/trucking'],
    [null, '/public'],
    [USER_ROLES.LOGISTICS_MANAGER, '/job-dashboard'],
  ]);

  /**
   * Calls login api to authenticate login.
   *
   * @param {*} { email, password }
   * @return {*}
   * @memberof LoginService
   */
  authenticateLogin({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Observable<Object> {
    const { client_id, grant_type, module } = this.config?.api;
    const params = {
      email,
      password: Buffer.from(password).toString('base64'),
      client_id,
      grant_type,
      module,
    };
    return this.http.post(
      `${this.config?.backendURL}/${this.config?.backendRestURL}/${ApiPaths.AUTH.LOGIN_ROUTE}`,
      params
    );
  }

  /**
   * Save access token in local storage.
   *
   * @param {string} token
   * @memberof LoginService
   */
  setUser(user: any): void {
    this.userProfileService.saveUserData(user);
  }

  /**
   * This method clears the Local Storage and reroutes to the login page.
   *
   * @memberof AuthService
   */
  public async logout() {
    await lastValueFrom(this.logoutFromServer());
    this.userProfileService.resetUserData();
    this.localStorage.removeAll(CONSTANTS.KEYS_TO_REMOVE);
    this.sessionStorage.removeAll(CONSTANTS.KEYS_TO_REMOVE);
    this.router.navigate(['/public', this.location.path()]);
  }

  public logoutFromServer(): Observable<boolean> {
    return this.genericService.post(ApiPaths.AUTH.LOGOUT_ROUTE, {
      token: this.localStorage.get<any>(STORAGE.TOKEN),
    });
  }

  /**
   * This method is to check is user logged in
   *
   *
   * @return {*}
   */
  public isLoggedIn(): Observable<boolean> {
    return this.genericService.get(ApiPaths.VALIDATE_TOKEN, undefined, {
      token: this.localStorage.get<any>(STORAGE.TOKEN),
    });
  }

  /**
   * This method is to route to default page when the user is guarded
   * to navigate to any specific page
   */
  public navigateToDefaultRoute(): boolean {
    const userRole =
      this.localStorage.get<any>(STORAGE.USER)?.roles?.[0] || null;
    const route = this.DEFAULT_ROUTES.get(userRole);
    if (userRole && route) {
      this.router.navigate([route]);
      return true;
    } else {
      this.notificationService.error(
        'Invalid user type. Please contact administrator.'
      );
      this.logout();
      return false;
    }
  }

  /**
   * If previous route exists then it will check if the user is permitted for the saved previous route.
   * If so then will redirect the user to that route and return true.
   * If not then will show error prompt and return false.
   *
   * @return {*}
   * @memberof AuthService
   */
  public navigateToPreviousRoute(previousRoute: string): boolean {
    if (!previousRoute) {
      return false;
    }

    const routeKeyMatches = previousRoute?.match(/\/([^/?]+)/);
    const routeKey = routeKeyMatches ? routeKeyMatches[1] : '';
    const allowedRoles = ROUTE_VIA_USER_ROLES[routeKey]?.roles || [];
    if (!allowedRoles.length) {
      return false;
    }

    if (
      previousRoute &&
      !previousRoute.split('/').includes('public') &&
      _.intersection(
        allowedRoles,
        this.localStorage.get<any>(STORAGE.USER)?.roles || []
      ).length !== 0
    ) {
      this.router.navigate([previousRoute]);
      return true;
    }

    this.notificationService.error(
      `User not authorized to view ${ROUTE_VIA_USER_ROLES[routeKey]?.label}. Redirecting to default page.`,
      {
        playSound: true,
      }
    );
    return false;
  }

  public isGuestRoute(): boolean {
    const url = this.location.path();
    const segments = url.split('/')[1];
    return (
      url !== undefined &&
      GuestRoutes.includes(segments) &&
      !this.userProfileService.hasToken()
    );
  }
}

<div class="fraction-range" [style.height.px]="height" [style.width.px]="width-2"
  [style.backgroundColor]="rangeStyle.backgroundColor" [style.borderColor]="rangeStyle.borderColor" [style.justify-content]="eventRecord.alignment">
  <div class="range-text">
    <div>
      {{eventRecord.available}}
    </div>
    <div class="divider"></div>
    <div class="total-quantity">
      {{eventRecord.total}}
    </div>
  </div>
  <ng-container *ngIf="overlayRangeStyle">
    <div [style.height.%]="childHeightPercentage" class="overlay-range" [style.width.px]="width-4"
      [style.backgroundColor]="overlayRangeStyle.backgroundColor" [style.borderColor]="overlayRangeStyle.borderColor">
    </div>
  </ng-container>
</div>

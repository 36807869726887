import { Component, Input } from '@angular/core';
import { NumericEventModel } from 'projects/freelancer-portal/src/app/modules/private/product-availability/models/events/numeric-event.model';
import { ProductLegendStyle } from '../../../../helpers/legends-list';
import { EventRangeService } from '../../../../../private/product-availability/services/product-availability-event-range.service';
import { TextEventModel } from 'projects/freelancer-portal/src/app/modules/private/product-availability/models/events/text-event.model';

@Component({
  selector: 'rtl-text-range',
  templateUrl: './text-range.component.html',
  styleUrls: ['./text-range.component.scss'],
})
export class TextRangeComponent {
  @Input() height: number = 0;
  @Input() width: number = 0;
  private _eventRecord!: TextEventModel;
  public rangeStyle!: ProductLegendStyle;

  @Input() set record(value: string) {
    if (value) {
      this._eventRecord = JSON.parse(value);
      this.rangeStyle = this._eventRangeService.getRangeColors(
        'lost'
      );
    }
  }

  get eventRecord(): TextEventModel {
    return this._eventRecord;
  }

  constructor(private _eventRangeService: EventRangeService) {}
}
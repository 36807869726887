import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { OuterAuthGuard } from './guards/outer-auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/private/layout/layout.module').then(
        (m) => m.LayoutModule
      ),
  },
  {
    path: 'public',
    canActivate: [OuterAuthGuard],
    loadChildren: () =>
      import('./modules/public/public.module').then((m) => m.PublicModule),
  },
  //{ path: '**', redirectTo: 'public', pathMatch: 'prefix' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<div class="product-resource">
  <div class="title-section">
    <div class="name" *ngIf="!record?.detailViewLink">
      {{ record?.name }}
    </div>
    <div *ngIf="record?.detailViewLink">
      <a [href]="record.detailViewLink" target="_blank" class="web-link">
        <div [matTooltip]="toolTip" class="name">
          {{ record?.name }}
        </div>
      </a>
    </div>
    <div *ngIf="record.serialNumber && record.serialNumber == record.name">
      <app-copy-button
        [textToCopy]="record.serialNumber"
        textTitle="Serial Number"
      ></app-copy-button>
    </div>
    <div *ngIf="record?.webOuterLink">
      <a [href]="record.webOuterLink" target="_blank" class="web-outer-link">
        <mat-icon matTooltip="Equipment Detail view"> open_in_new </mat-icon>
      </a>
    </div>
  </div>
</div>
<div
  *ngIf="record.serialNumber && record.serialNumber != record.name"
  class="serial-with-copy"
>
  <a
    *ngIf="
      record.purpose &&
        [EQUIPMENT_PURPOSE.INDIVIDUAL_ITEM, EQUIPMENT_PURPOSE.KIT].includes(
          record.purpose
        );
      else noLink
    "
    [href]="record.webOuterLink"
    target="_blank"
    class="web-outer-link"
  >
    {{ record.serialNumber }}</a
  >
  <ng-template #noLink>
    {{ record.serialNumber }}
  </ng-template>
  <app-copy-button
    [textToCopy]="record.serialNumber"
    textTitle="Serial Number"
  ></app-copy-button>
</div>
